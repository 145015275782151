import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

const ResetSuccess = () => {
  const history = useHistory();

  const handleReset = () => {
    history.push('/login')
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-sm p-4">
        <h1 className="text-3xl mb-6 text-black font-bold text-center flex items-center justify-center">Password reset</h1>
        <p className="text-black text-center flex items-center justify-center ">
          Your password have been successfully reset. Click below log in magically.
        </p>
        <button
          className="w-full mt-6 bg-black text-white flex items-center justify-center py-2"
          onClick={handleReset}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ResetSuccess;
