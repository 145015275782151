import React, { useState, useEffect } from 'react';
import TableEdit from "./TableEdit";
import Clock from "./Clock";
import mqtt from 'mqtt';

const brokerUrl = 'wss://broker.emqx.io:8084/mqtt';

const Maintenance = () => {
  const [client, setClient] = useState(null);
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [valid, setValid] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Define or import your headings
  const headings = ["Name", "Phone", "Code", "Start Date", "Start Time", "End Date", "End Time"];

  // Update the valid state when input change
  useEffect(() => {
    setValid(
      name && 
      phone &&
      startDate &&
      endDate &&
      code?.length === 6 &&
      start &&
      end
    );
  }, [name, phone, code, startDate, start,  endDate, end]);

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl);
    mqttClient.on('connect', () => {
      console.log('Connected to MQTT broker');
      setClient(mqttClient);
      mqttClient.subscribe('d8:3a:dd:33:85:f3')
    });

    mqttClient.on('message', (topic, message) => {
      console.log(`Received message on topic ${topic}: ${message.toString()}`);
      const newData = JSON.parse(message.toString());
      console.log('New Data:', newData);
      setRows(prevRows => [...prevRows, newData]);
    });
    
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [client]);
  
  const handleNewCode = () => {
    if (client) {
      const topic = 'd8:3a:dd:33:85:f3';
      const payload = {
        name,
        phone,
        code,
        startDate,
        start,
        endDate,
        end
      };
  
      console.log('Publishing data:', payload);
      client.publish(topic, JSON.stringify(payload));
      console.log(`Published message on topic ${topic}: ${JSON.stringify(payload)}`);
      // Convert values of the payload object into an array
      // const newRow = Object.values(payload);
      // Update rows state with the new data
      // setRows(prevRows => [...prevRows, newRow]);
    }
  };
  
  const handleDeleteCode = codeToDelete => {
    console.log('Deleting code with code:', codeToDelete);

    if (client) {
      console.log('MQTT client is connected');
    } else {
      console.log('MQTT client is not connected');
    }

    const topic = 'd8:3a:dd:33:85:f3';

    // Find the index of the row with the specified code
    const rowIndex = rows.findIndex(row => row.code === codeToDelete);

    if (rowIndex !== -1) {
      // Create a new array without the row at the found index
      const newData = [...rows.slice(0, rowIndex), ...rows.slice(rowIndex + 1)];
      console.log('New data after deletion:', newData);
      setRows(newData);

      // publish deleted code information to Raspberry Pi via MQTT
      client.publish(topic + '/delete', JSON.stringify({ code: codeToDelete}));
    } else {
      console.log('Row with code ' + codeToDelete + 'not Found');
    }
  };
   
  return (
    <div className={`container mx-auto mt-4 px-4 md:flex md:justify-start md:flex-col ${isSidebarOpen ? 'lg:ml-84' : ''} transition-all duration-300 ease-in-out`}>
      <div className=""></div>
      <div className="md:flex md:flex-wrap mt-6">
        <div className="md:w-1/3 md:pr-2">
          <div className="field">
            <label className="label" style={{color:'#444444'}} >Name</label>
            <p className="control">
              <input className="input" type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
            </p>
          </div>
        </div>

        <div className="md:w-1/3 md:pr-4">
          <div className="field">
            <label className="label" style={{color:'#444444'}}>Phone</label>
            <p className="control">
              <input className="input" type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)}/>
            </p>
          </div>
        </div>

        <div className="md:w-1/3">
          <div className="field">
            <label className="label" style={{color:'#444444'}}>Code</label>
            <p className="control">
              <input className="input" type="number" placeholder="Code" value={code} onChange={e => setCode(e.target.value)}/>
            </p>
          </div>
        </div>
      </div>

      <div className="md:flex md:flex-wrap mb-4">
        <div className="md:w-1/3 md:pr-2">
          <div className="field">
            <label className="label mt-2" style={{color:'#444444'}}>Start Date</label>
            <p className="control">
              <input id="datepicker" className="input" type="date" placeholder="Date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
            </p>
          </div>
        </div>

        <div className="md:w-1/3 md:pr-2">
          <div className="field">
            <label className="label mt-2" style={{color:'#444444'}}>Start Time</label>
            <p className="control">
              <input className="input" type="time" placeholder="StartTime" value={start} onChange={e => setStart(e.target.value)}/>
            </p>
          </div>
        </div>

        <div className="md:w-1/3">
          <div className="field">
            <label className="label mt-2" style={{color:'#444444'}}>End Date</label>
            <p className="control">
              <input id="datepicker" className="input" type="date" placeholder="Date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
            </p>
          </div>
        </div>

        <div className="md:w-1/3">
          <div className="field">
            <label className="label mt-2" style={{color:'#444444'}}>End Time</label>
            <p className="control">
              <input className="input" type="time" placeholder="EndTime" value={end} onChange={e => setEnd(e.target.value)}/>
            </p>
          </div>
        </div>
      </div>

      <button
        className="button is-link is-outlined mt-2 w-20" style={{color:'#444444'}}
        disabled={!valid}
        onClick={() => handleNewCode({ name, phone, code, startDate, start, endDate, end })}
      >
        Save
      </button>
      <Clock />
      <TableEdit headings={headings} rows={rows} handleDeleteCode={handleDeleteCode} />
    </div>
  );
};

export default Maintenance;
