import { SET_CODES } from '../actions';

const codes = (state = [], action) => {
  switch (action.type) {
    case SET_CODES:
      return action.codes;
    default:
      return state;
  }
}

export default codes;