import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useSingleSession = () => {
  const history = useHistory();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'sessionId' && !localStorage.getItem('sessionId')) {
        alert('You have been logged out because a new session has been started in another tab.');
        localStorage.removeItem('userData');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('lastLoginChange');
        setTimeout(() => {
            window.location.reload();
          }
          , 1000);
        history.push('/login');
      }
      
      else if (event.key === 'lastLoginChange') {
        setTimeout(() => {
          window.location.reload();
        }
        , 1000);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [history]);
};
