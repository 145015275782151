import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import download from '../Assets/download.svg';
import { useSwipeable } from 'react-swipeable';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import search from '../Assets/search.svg';
import './styles.css';
import icon from '../Assets/iconS.svg';

const Actions = ({ setIsMenuOpen }) => {
  const [deviceData, setDeviceData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [doorStates, setDoorStates] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);
  const [responses, setResponses] = useState({});
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      setUserData(JSON.parse(userDataFromStorage));
    }
  }, []);
 
  useEffect(() => {
    if (userData) {
      const apiUrl = 'https://eunitstest.onrender.com/api/getdevice';
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const controlDevices = data.filter(device => device.category === 'Control');
          if (userData && userData.role === "Company Admin") {
            setDeviceData(controlDevices.filter(device => device.company === userData.company));
          } else if (userData && userData.role === "Company User") {
            setDeviceData(controlDevices.filter(device => device.company === userData.company));
          } else {
            setDeviceData(controlDevices);
          }
        })
        .catch(error => console.error('Error fetching device data:', error));
    }
  }, [userData]);

  useEffect(() => {
    if (userData && deviceData.length > 0) {
      fetch('https://eunitstest.onrender.com/api/getactiondetails')
        .then(response => response.json())
        .then(actionDetails => {
          const newSwitchStates = deviceData.map(device => {
            const foundAction = actionDetails.find(action => action.macAddress === device.macAddress);
            return foundAction ? foundAction.alwaysopen === 'true' : false;
          });
          setSwitchStates(newSwitchStates);
        })
        .catch(error => console.error('Error fetching action details:', error));
    }
  }, [userData, deviceData.length]); 

  const handleToggleSlider = (macAddress) => {
    const doorIndex = deviceData.findIndex(device => device.macAddress === macAddress);
    if (doorIndex === -1) return;

    const newSwitchState = !switchStates[doorIndex];
    const newSwitchStates = [...switchStates];
    newSwitchStates[doorIndex] = newSwitchState;
    setSwitchStates(newSwitchStates);

    const payload = {
      macAddress: deviceData[doorIndex].macAddress,
      dooropen: newSwitchState ? 'true' : 'false',
      alwaysopen: newSwitchState ? 'true' : 'false',
      status: 'notdone'
    };

    fetch('https://eunitstest.onrender.com/api/addactiondetails', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to update switch state for index ${doorIndex}`);
      }
      console.log(`Switch state updated successfully for index ${doorIndex}`);
    })
    .catch(error => {
      console.error('Error updating switch state:', error);
    });
  };

  // useEffect(() => {
  //   if (userData) {
  //     const apiUrl = 'https://eunitstest.onrender.com/api/getdevice';
  //     fetch(apiUrl)
  //       .then(response => response.json())
  //       .then(data => {
  //         const controlDevices = data.filter(device => device.category === 'Control');
  //         if (userData.role === "Company Admin" || userData.role === "Company User") {
  //           setDeviceData(controlDevices.filter(device => device.company === userData.company));
  //         } else if (userData.role === 'System Admin') {
  //           setDeviceData(controlDevices);
  //         }
  //       })
  //       .catch(error => console.error('Error fetching device data:', error));
  //   }
  // }, [userData]);  
  
  const customStyles = {
    rows: {
      style: {
        minHeight: '62px',
      },
    },

    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };

  const columns = [
    {
      name: 'Mac Address',
      selector: row => row.macAddress,
      sortable: true,
    },

    {
      name: 'Venue',
      selector: row => row.venue,
      sortable: true,
    },

    {
      name: 'Entryway',
      selector: row => row.entryway,
      sortable: true,
    },
    // {
    //   name: 'Category',
    //   selector: row => row.category,
    //   sortable: true,
    // },
    {
      name: 'Company',
      selector: row => row.company,
      sortable: true,
    },
  ];

  if (userData && userData.role !== "Company User") {
    columns.push({
      name: 'Action',
      cell: row => (
        <div className='flex items-center gap-3'>
          <button
            className='py-2 px-4 font-bold'
            style={{ backgroundColor: '#010808', color:'#FFFFFF', lineHeight:'28px'}}
            onClick={() => handleOpenDoorForRow(row.macAddress)}
            disabled={loading || selectedRowIndex === deviceData.findIndex(device => device.macAddress === row.macAddress) || doorStates[deviceData.findIndex(device => device.macAddress === row.macAddress)]}
          >
            {doorStates[deviceData.findIndex(device => device.macAddress === row.macAddress)] ? 'Door Opened' : 'Open Door'}
          </button>
    
          <p className={responses[row.macAddress] === 'Success' ? 'has-text-success text-center pl-5 pr-5 mb-2.5' : responses[row.macAddress] === 'Failed' ? 'has-text-failure text-center pl-5 pr-5 mb-2.5' : 'hidden'}>
            {responses[row.macAddress] === 'Success' ? 'Success' : responses[row.macAddress] === 'Failed' ? 'Something failed' : ''}
          </p>
        </div>
      ),
    },
    {
      name: 'Keep open',
      selector: row => row.customizeAction,
      wrap: true, 
      button: true,
      cell: row => (
        <div className="toggle-switch last-column-cell"> 
          <label className="switch">
            <input
              type="checkbox"
              checked={switchStates[deviceData.findIndex(device => device.macAddress === row.macAddress)]}
              onChange={() => handleToggleSlider(row.macAddress)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ),
    });
  }

  const filteredData = deviceData.filter(device => {
    return device.macAddress.toLowerCase().includes(filter.toLowerCase()) ||
           device.venue.toLowerCase().includes(filter.toLowerCase()) ||
           device.entryway.toLowerCase().includes(filter.toLowerCase()) ||
           device.company.toLowerCase().includes(filter.toLowerCase());
  });

  const handleOpenDoorForRow = (macAddress) => {
    const doorIndex = deviceData.findIndex(device => device.macAddress === macAddress);
    if (doorIndex === -1 || loading || doorStates[doorIndex]) {
      return;
    }
  
    setLoading(true);
    setSelectedRowIndex(doorIndex);
  
    const payload = {
      macAddress: macAddress,
      dooropen: 'true',
      alwaysopen: switchStates[doorIndex] ? 'true' : 'false',
      status: 'notdone'
    };
  
    fetch('https://eunitstest.onrender.com/api/addactiondetails', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to open the door');
      }
      return response.json();
    })
    .then(data => {
      const newDoorStates = [...doorStates];
      newDoorStates[doorIndex] = true;
      setDoorStates(newDoorStates);
      setResponses(prev => ({...prev, [macAddress]: 'Success'}));
      setTimeout(() => {
        setResponses(prev => ({...prev, [macAddress]: undefined}));
      }, 5000);
    })
    .catch(error => {
      console.error('Error:', error);
      setResponses(prev => ({...prev, [macAddress]: 'Failed'}));
      setTimeout(() => {
        setResponses(prev => ({...prev, [macAddress]: undefined}));
      }, 5000);
    })
    .finally(() => {
      setLoading(false);
      setSelectedRowIndex(null);
    });
  };
  
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div className="p-8">
      <div {...handlers} className="">
        <div className='text-black md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden'>
        <img
            src={icon}
            alt="User"
            className=""
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Actions</span>
        </div>
        <div className="bg-gray-100 text-black flex flex-col items-start md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col pb-6 md:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Actions</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Available actions listed below</p>
          </div>
          <div className="flex flex-col items-start mt-6 md:items-end">
            <div className="bg-white" >
              <CSVLink
                data={deviceData}
                filename={"device_data.csv"}
                className="custom-width flex items-center justify-center gap-2 border bg-white border-gray-300 md:px-3 py-2" style={{color:"#010808"}}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{fontSize:'18px', lineHeight:'28px',color:'#010808', letterSpacing:'0%'}}>Export</span>
              </CSVLink>
            </div>
            <div className='custom-width flex flex-col mt-4 mb-4 md:mt-0 md:mb-0'>
              <p className="text-black hidden lg:block md:block">Search</p>
              <div className="relative">
                <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />
                <input
                  className="px-12 md:px-10 w-full text-black py-2 border focus:outline-none"
                  style={{
                    outline: 'none',
                    '&:focus': {
                      borderColor: '#349999'
                    },
                  }}
                  type="text"
                  placeholder="Search by keyword"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='overflow-x-auto pt-8'>
        <DataTable
          columns={columns}
          data={filteredData}
          customStyles={customStyles}
          pagination
          persistTableHead
          highlightOnHover
          pointerOnHover
          sortIcon={<MdArrowUpward />}
          // defaultSortFieldId={1}
        />
      </div>
    </div>

  );
};

export default Actions;
