import React, { useState } from 'react';
import icon from '../Assets/iconS.svg';
import icons from '../Assets/icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const initialTopRow = [' ', ' ', ' ', ' ', ' ', ' '];
const bottomRow = ['*', '*', '*'];
const keys = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#'],
  ['X', 'OK', 'icons']
];

const EntroKeypad = () => {
  const [topRow, setTopRow] = useState(initialTopRow);

  const handleKeyPress = (key) => {
    setTopRow(prev => {
      if (key === 'X') {
        const lastFilledIndex = prev.lastIndexOf(prev.find(el => el.trim()));
        if (lastFilledIndex !== -1) {
          const updated = [...prev];
          updated[lastFilledIndex] = ' ';
          return updated;
        }
        return prev;
      } else {
        const updated = [...prev, key];
      if (updated.length > 6) {
        updated.shift();
      }
      return updated;
    }
    });
  };
  
  return (
    <div className='p-8'>
        <div className='md:flex md:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className="bg-gray-100"
          />
          <FontAwesomeIcon icon={faChevronRight} />
          <span className='font-bold' style={{lineHeight:'20px', fontSize:'14px'}}>Keypad</span>
        </div>

       <div className="flex flex-col pt-4 pb-4 md:pb-28">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Entro Keypad</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Get access by entering the 6-digits code in the show form</p>
        </div>

        <div className="max-w-xs mx-auto">
            <div className="flex flex-col items-center">
                <div className="flex w-full">
                  {topRow.slice(0, 4).map((key, index) => (
                    <button
                        key={index}
                        className="flex-1 m-1 p-4 text-xl bg-white border-2 border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:border-blue-500"
                        style={{color:"#010808"}}
                    >
                        {key || ' '}
                    </button>
                  ))}
                </div>
                <div className="flex flex-row w-full">
                  {topRow.slice(4).map((key, index) => (
                    <button
                        key={index + 4}
                        className="m-1 p-4 text-xl bg-white border-2 border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:border-blue-500"
                        style={{ width: '100%', color:"#010808" }} 
                    >
                        {key || ' '}
                    </button>
                  ))}
                </div>
            </div>
            {/* <div className="flex justify-center">
                {bottomRow.map((key, index) => (
                <button
                   key={index}
                   className="flex-1 m-1 p-4 text-black text-xl bg-white border-2 border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:border-blue-500"
                >
                    {key}
                </button>
                ))}
            </div> */}
            
            {keys.map((row, rowIndex) => (
              <div key={rowIndex} className="flex" style={{color:"#010808"}}>
                  {row.map((key, keyIndex) => (
                      <button
                          key={keyIndex}
                          className="flex-1 p-3 text-xl bg-white border-2 border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:border-blue-500"
                          onClick={() => handleKeyPress(key)}
                      >
                        {key === 'icons' ? <img className='pl-7' src={icons} alt="Symbol" /> : key}
                      </button>
                  ))}
              </div>
            ))}

        </div>
    </div>
  );
}

export default EntroKeypad;
