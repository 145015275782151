import React from 'react';
import DataTable from 'react-data-table-component';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import trash from '../Assets/trash.svg';
import edit from '../Assets/edit-05.png';

const customSortIcon = <MdArrowUpward />;
const TableConnection = ({ headings, rows, onEditConnection, onDelete }) => {
  const displayNames = {
    Connection_ID:"#ID",
    company: "Company",
    category: "Category",
    status: "Status",
    Connection:"Name",
    ParameterMapping:"Pattern",
    SharedSecret:"Integration",
    actions: "Actions",
    Actions: "Actions",
  };

  const columns = headings.map(heading => ({
    name: displayNames[heading] || heading,
    selector: row => row[heading] || '',
    sortable: true,
    cell: (row) => {
      
      if (heading === 'Actions') {
        return (
          <div className="flex flex-row gap-1 text-gray-600">
            <img src={trash} alt="Delete" className="w-5 h-5 cursor-pointer" onClick={() => onDelete(row.Connection_ID)} />
            <button onClick={() => onDelete(row.Connection_ID)} className="text-red-600">
              Delete
            </button>
          </div>
        );

      } else if ((heading === 'actions')) {
        return (
          <div className="flex flex-row gap-1 text-gray-600">
            <img src={edit} alt="Edit" className="w-5 h-5 cursor-pointer" onClick={() => onEditConnection(row)} />
            <button onClick={() => onEditConnection(row)} className="hover:underline text-gray-600">
              Edit
            </button>
          </div>
        );
      } 
      
      else if (heading === 'status') {
        const color = row.status === 'Active' ? '#027A48' : '#B42318'; 
        const backgroundColor = row.status === 'Active' ? '#ECFDF3' : '#FEF3F2';
        return (
          <button 
            className="flex flex-row items-center gap-2 px-2 py-1" 
            style={{ backgroundColor }}
          >
            <span style={{ color: color }}>{row.status}</span>
          </button>
        );
      } else {
        return row[heading] || '';
      }
    }
  }));

  const customStyles = {
    rows: {
      style: {
        minHeight: '52px',
      }
    },
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        paddingLeft: '28px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '26px',
        paddingRight: '8px',
      },
    },
  };

  return (
    <DataTable
      columns={columns}
      data={rows}
      customStyles={customStyles}
      pagination
      responsive
      paginationPerPage={10}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
      highlightOnHover
      persistTableHead
      sortIcon={customSortIcon}
    />
  );
};

export default TableConnection;
