import React, { useState } from 'react';

const CustomDropdown = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedOption = options.find(option => option.value === value) || options[0];
    return (
      <div className="relative mt-2">
        <div className="cursor-pointer text-black border px-2 py-1.5 w-full flex justify-between items-center pr-8" onClick={() => setIsOpen(!isOpen)}>
          {selectedOption.label}
          <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '20px' }} />
        </div>
        {isOpen && (
          <div className="absolute text-black top-full left-0 right-0 z-10 border bg-white pr-6">
            {options.map(option => (
              <div key={option.value} className="flex justify-between items-center px-2 py-1.5 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}>
                {option.label}
                <img src={option.icon} alt={option.label} style={{ width: '20px' }} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
};

export default CustomDropdown;