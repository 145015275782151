import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TableDevice from './TableDevice';
import EControlUnitForm from './eControlUnit';
import DeviceSettingsForm from './DeviceSettings';
import { CSVLink } from 'react-csv';
import icon from '../Assets/iconS.svg';
import axios from 'axios';
import download from '../Assets/download.svg';
import plus from '../Assets/plus.svg';
import search from '../Assets/search.svg';
import './styles.css';

const Device = ({setIsMenuOpen}) => {
  const headings = ["macAddress", "hardwareDetails", "category", "description", "entryway", "venue", "company", "heartbeat"];

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedDeviceData, setSelectedDeviceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [selectedDeviceForSettings, setSelectedDeviceForSettings] = useState(null);
  const location = useLocation();

  const onSettingsClick = (device) => {
    console.log('Settings clicked for device:', device); 
    setSelectedDeviceForSettings(device);
    setShowSettingsModal(true);
  };

  const closeModalSettings =() => {
    setShowSettingsModal(false);
    setSelectedDeviceForSettings(null);
  }

  const diacriticChars = {
    'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae',
    'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
    'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
    'ð': 'd', 'ñ': 'n',
    'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o',
    'ø': 'o', 'œ': 'oe',
    'ß': 'ss',
    'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',
    'ý': 'y', 'ÿ': 'y',
    'ā': 'a', 'ă': 'a', 'ą': 'a', 'ć': 'c', 'č': 'c', 'ç': 'c',
    'ď': 'd', 'đ': 'd', 'ē': 'e', 'ĕ': 'e', 'ė': 'e', 'ę': 'e', 'ě': 'e',
    'ğ': 'g', 'ġ': 'g', 'ģ': 'g', 'ħ': 'h', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'į': 'i', 'ı': 'i',
    'ĳ': 'ij', 'ķ': 'k', 'ĸ': 'k', 'ĺ': 'l', 'ļ': 'l', 'ľ': 'l', 'ŀ': 'l', 'ł': 'l',
    'ń': 'n', 'ņ': 'n', 'ň': 'n', 'ŋ': 'n', 'ō': 'o', 'ŏ': 'o', 'ő': 'o', 'œ': 'oe',
    'ŕ': 'r', 'ŗ': 'r', 'ř': 'r', 'ś': 's', 'š': 's', 'ŝ': 's', 'ş': 's', 'ș': 's', 'ß': 'ss',
    'ť': 't', 'ţ': 't', 'ț': 't', 'ŧ': 't', 'ũ': 'u', 'ū': 'u', 'ŭ': 'u', 'ů': 'u', 'ű': 'u', 'ų': 'u',
    'ŵ': 'w', 'ŷ': 'y', 'ÿ': 'y', 'ź': 'z', 'ż': 'z', 'ž': 'z',
    'í': 'i', 'Í': 'i',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h',
    'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p',
    'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x',
    'y': 'y', 'z': 'z'
  };
  
  const normalizeString = (str) => {
    return str.replace(/[^\u0000-\u007E]/g, (match) => diacriticChars[match] || match);
  };
  
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }

    const params = new URLSearchParams(location.search);
    const companyFilter = params.get('filter');
    
    if (companyFilter) {
      setFilter(companyFilter);
    }
  }, [location]);

  if (userData && (userData.role === 'System Admin' || userData.role === 'Company Admin')) {
    headings.push("error_description","debug_log", "version","Status", "Actionss", "actions", "Actions",);
  }

  const handleStatusCheck = async (device, index) => {
    try {
      const payload = {
        macaddress: device.macAddress,
        status: 'check'
      };
      const response = await axios.post(`https://eunitstest.onrender.com/device/deviceactivity`, payload);
      const newStatus = response.data.status;
      const updatedData = [...data];
      updatedData[index] = { ...device, status: newStatus };
      setData(updatedData);
    } catch (error) {
      console.error('Error checking status:', error);
    }
  };
  
  useEffect(() => {
    const fetchDataByRole = async () => {
      try {
        if (userData && (userData.role === 'Company User' || userData.role === 'Company Admin') ) {
          const response = await axios.get('https://eunitstest.onrender.com/api/getdevice');
          const filteredData = response.data.filter(device => device.company === userData.company);
          setData(filteredData);
          setResult(filteredData);
          setRows(filteredData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataByRole();
  }, [userData]);

  useEffect(() => {
    const fetchDataByAdmin = async () => {
      try {
        if (userData && userData.role === 'System Admin') {
          const response = await axios.get('https://eunitstest.onrender.com/api/getdevice');
          console.log('Response status:', response.status);
          console.log('Data fetched:', response.data);
          
          let initialData = response.data;
          const normalizedFilter = normalizeString(filter);
  
          if (normalizedFilter) {
            initialData = initialData.filter(device => normalizeString(device.company) === normalizedFilter);
          }
  
          const filteredData = applyFilter(initialData, normalizedFilter, headings);
          setResult(filteredData);
          const indexOfLastItem = currentPage * itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
          setRows(currentItems);
          setData(initialData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataByAdmin();
  }, [userData, filter, currentPage]);
  
  const applyFilter = (data, filter, headings) => {
    if (filter.length >= 3) {
      const normalizedFilter = normalizeString(filter);
      const filtered = data.filter((row) => {
        return headings.some((heading) => {
          const rowValue = normalizeString(String(row[heading] || ''));
          return rowValue.includes(normalizedFilter);
        });
      });
      return filtered;
    } else {
      return data;
    }
  };

  const handleFilterChange = (value) => {
    setFilter(value);
    if (value.length >= 3) {
      const filteredData = applyFilter(result, value, headings);
      setData(filteredData);
    } else {
      setData(result);
    }
  };

  const handleDelete = async (unitId) => {
    try {
      console.log('Dynamic unitId:', unitId);
      const response = await axios.post('https://eunitstest.onrender.com/api/deletedevice', { unitId });
      if (response.status === 200) {
        const updatedData = data.filter((device) => device.unitId !== unitId);
        setData(updatedData);
        const filteredData = applyFilter(updatedData, filter, headings);
        setResult(filteredData);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setRows(currentItems);
      } else {
        console.error('Error deleting device:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting device:', error);
    }
  };

  const openModal = () => {
    setSelectedDeviceData(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDeviceData(null);
  };

  const handleEditDevice = (userData) => {
    setSelectedDeviceData(userData);
    setShowModal(true);
  };

  const updateTableData = (updatedDeviceData) => {
    const updatedData = data.map(device => {
      if (device.unitId === updatedDeviceData.unitId) {
        return updatedDeviceData;
      }
      return device;
    });
    console.log(updatedData)
    setData(updatedData);
  
    const filteredRows = applyFilter(updatedData, filter, headings);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRows.slice(indexOfFirstItem, indexOfLastItem);
    setRows(currentItems);
  };
  
  const handleConnectionUpdated = (newDeviceData) => {
    setData(prevData => [...prevData, newDeviceData]);
    const updatedRows = [...rows, newDeviceData];
    setRows(updatedRows);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  const updateDeviceData = (updatedDeviceData) => {
    console.log('Updated company data:', updatedDeviceData);
    const updatedRows = rows.map(row => {
      if (row.unitId === updatedDeviceData.unitId) {
        return updatedDeviceData;
      }
      return row;
    });
  
    setRows(updatedRows);

    const updatedData = data.map(device => {
      if (device.unitId === updatedDeviceData.unitId) {
        return { ...device, ...updatedDeviceData };
      }
      return device;
    });

    setData(updatedData);
    setSelectedDeviceForSettings(updatedDeviceData);
  };
  
  return (
    <div 
      className={
        `pt-0 md:pt-8 ${userData ? (userData.role === 'System Admin' 
        ? 'main-content' : userData.role === 'Company Admin' 
        ? 'main-content-company' : '') : ''}`
      }
    >
      <div {...handlers} className="pr-8 pl-8">
        <div className='md:flex md:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img src={icon} alt="User" className="" />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Devices</span>
        </div>

        <div className="bg-gray-100 flex flex-col items-start md:flex-row md:items-center md:justify-between" style={{color:"#010808"}}>
          <div className="flex flex-col pb-8 md:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'010808', lineHeight:'44px', letterSpacing:'2%'}}>Devices</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Below all of your devices are listed.</p>
          </div>

          <div className="flex flex-col mt-0 md:mt-4">
            <div className="flex flex-col items-start md:flex-row md:items-center md:justify-end gap-2">
              <CSVLink
                data={data}
                headers={headings}
                filename={'devices.csv'}
                className="custom-width flex items-center justify-center md:justify-center gap-2 border bg-white border-gray-300 md:px-3 py-2" style={{ color: "#010808" }}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', color: '#010808', letterSpacing: '0%' }}>Export</span>
              </CSVLink>

              {userData && userData.role === 'System Admin' && (
                <button onClick={openModal} className="flex items-center justify-center md:justify-center gap-2 border text-white border-gray-300 w-full md:w-auto py-2 md:px-3" style={{ backgroundColor: '#010808' }}>
                  <img
                    src={plus}
                    alt="User"
                    className=""
                  />
                  <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', backgroundColor: '#010808', letterSpacing: '0%' }}>Add Device</span>
                </button>
              )}
            </div>

            <div className="custom-widthl relative mt-6 md:mt-0">
              <span className="text-black hidden lg:block md:block pt-6">Search</span>
              <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />
              <input
                className="w-full px-10 text-gray-500 py-2 border rounded focus:outline-none"
                type="text"
                placeholder="Search by keyword"
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 pt-8 ml-8 mr-8 overflow-x-auto overflow-auto">
        <TableDevice 
          headings={headings} 
          rows={data} 
          onDelete={handleDelete} 
          onEditDevice={handleEditDevice} 
          updateTableData={updateTableData} 
          userData={userData} 
          onStatusCheck={handleStatusCheck} 
          onSettingsClick={onSettingsClick} 
        />
      </div>

      {showModal && (
        <div className="fixed">
          <EControlUnitForm
            closeModal={closeModal}
            editMode={selectedDeviceData !== null}
            deviceData={selectedDeviceData}
            onConnectionUpdated={handleConnectionUpdated} 
            updateTableData={updateTableData}
            rows={rows}
            setRows={setRows}
          />
        </div>
      )}

      {showSettingsModal && (
        <div className="fixed">
            <DeviceSettingsForm
              device={selectedDeviceForSettings}
              onClose={closeModalSettings}
              updateDeviceData={updateDeviceData}
            />
      </div>
)}
    </div>
  );
};

export default Device;
