export const SET_EVENTS = 'SET_EVENTS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_CODES = 'SET_CODES';
export const SET_SIGNUP = 'SET_SIGNUP';
export const SET_LOGIN = 'SET_LOGIN'; 
export const SET_USER_DATA = 'SET_USER_DATA';

export const setEvents = events => ({
  events,
  type: SET_EVENTS,
});

export const setBookings = bookings => ({
  bookings,
  type: SET_BOOKINGS,
});

export const setCodes = codes => ({
  codes,
  type: SET_CODES,
});

export const SET_USER = 'SET_USER';

export const setUser = user => ({
  user,
  type: SET_USER,
});

// actions/index.js
export const RESET_USER = 'RESET_USER';

export const resetUser = () => ({
  type: RESET_USER,
});

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});



