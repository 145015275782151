import { useState } from "react";
import { useEffect } from "react";

const Clock = () => {
  const [clock, setClock] = useState('');
  
  useEffect(() => {
    const getTime = () => {
      const now = new Date().toLocaleString('sv-SE')
      return now.split(' ')[1]
    }
    setInterval(setClock, 1000, getTime)
  },[])

  return (
    <p className="header has-text-link">
      {clock}
    </p>
  )
}

export default Clock;