import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { resetPasswordRequestApi } from '../../js/api';
import iconL from '../../Assets/left.svg';

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPasswordRequest = async (event) => {
    event.preventDefault();
  
    try {
      setLoading(true);
      const response = await resetPasswordRequestApi(email);
  
      if (response.msg === 'email not exist') {
        setError('Email not found. Please check your email address.');
      } else {
        setSuccessMessage('Password reset link sent successfully');
        history.push('/reset-password-success');
      }
    } catch (error) {
      setError('Reset password request failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="p-4 ">
        <h2 className="text-4xl pb-4 text-black text-center font-bold ">Forgot Password?</h2>
        <span className='text-black text-center flex items-center justify-center'>No worries, we'll send to you reset instructions</span>
        {successMessage && <p className="text-green-500 mb-4 flex items-center justify-center">{successMessage}</p>}
        {error && <p className="text-red-500 mb-4 flex items-center justify-center">{error}</p>}
        <form onSubmit={handleResetPasswordRequest}>
          <div className="pb-6 pt-8">
            <label className="text-gray-700 mb-2">Email</label>
            <input
              placeholder="Enter your Email"
              className="mt-1 w-full p-2 border text-gray-500 focus:outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-6">
            <button
              className="w-full bg-black text-white disabled:opacity-50 py-2"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <FontAwesomeIcon icon={faSpinner} spin /> Sending...
                </span>
              ) : (
                'Reset Password'
              )}
            </button>
          </div>
        </form>

        <div className="flex items-center justify-center mt-8">
          <Link to="/login" className="flex items-center justify-center text-black hover:underline">
            <img src={iconL} alt="User" className="mr-2" /> Back to Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
