import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import Sidebar from './SideBar';
import { MainPage } from './MainPage';
import Bookings from './Bookings';
import Events from './Events';
import Connection from './Connection';
import Users from './Users';
import Device from './Device';
import Companies from './Companies';
import Maintenance from './Maintenance';
import Actions from './Actions';
import Profile from './Profile';
import Help from './Help';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import { useSingleSession } from './Auth/useSingleSession';
import EntroKeypad from './EntroKeypad';
import SmsLogModal from './SmsLog';
import ViewSMSLog from './SMSViewLog';

const SystemAdmin = () => {
  useSingleSession();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('userId');
  const userData = location.state?.userData;
  const storedRole = localStorage.getItem('role');

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    } else if (storedRole !== 'System Admin' && storedRole !== 'Company User' && storedRole !== 'Company Admin') {
      history.push('/login');
    }
  }, [isAuthenticated, storedRole, history]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const basename = storedRole === 'System Admin' ? '/admin' : '/admin';
  const initialRedirect = storedRole === 'System Admin' ? '/dashboard' : '/dashboard';

  return (
    <Router basename={basename}>
      <div className="flex">
        <div className="hidden md:block">
          <Sidebar userData={userData} />
        </div>
        
        <div className="bg-gray-100 flex flex-col flex-1">
          <div className="block lg:hidden md:hidden">
            <Navbar userData={userData} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          </div>
          <Switch>
            <Route path="/dashboard">
              <MainPage>
                <Dashboard userData={userData} setIsMenuOpen={setIsMenuOpen}/>
              </MainPage>
            </Route>

            <Route path="/keypad">
              <MainPage>
                <EntroKeypad userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>
            
            <Route path="/booking">
              <MainPage>
                <Bookings userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/sms">
              <MainPage>
                <ViewSMSLog userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/events">
              <MainPage>
                <Events userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/maintenance">
              <MainPage>
                <Maintenance userData={userData} />
              </MainPage>
            </Route>

            <Route path="/actions">
              <MainPage>
                <Actions userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/connections">
              <MainPage>
                <Connection userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/users">
              <MainPage>
                <Users userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/companies">
              <MainPage>
                <Companies userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/devices">
              <MainPage>
                <Device userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/profile">
              <MainPage>
                <Profile userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route path="/help">
              <MainPage>
                <Help userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Route exact path="/">
              <MainPage>
                <Dashboard userData={userData} setIsMenuOpen={setIsMenuOpen} />
              </MainPage>
            </Route>

            <Redirect to={initialRedirect} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default SystemAdmin;
