import React, { createContext, useContext, useState } from 'react';

const PhoneNumberFormatContext = createContext(null);

export const PhoneNumberProvider = ({ children }) => {
  const [isInternational, setIsInternational] = useState(false);

  const toggleInternationalFormat = () => {
    setIsInternational(prevState => !prevState);
  };
  console.log(isInternational);

  return (
    <PhoneNumberFormatContext.Provider value={{ isInternational, toggleInternationalFormat }}>
      {children}
    </PhoneNumberFormatContext.Provider>
  );
};

export const usePhoneNumberFormat = () => useContext(PhoneNumberFormatContext);
