import { SET_BOOKINGS } from '../actions';

const bookings = (state = [], action) => {
  switch (action.type) {
    case SET_BOOKINGS:
      return action.bookings;
    default:
      return state;
  }
}

export default bookings;