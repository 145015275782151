import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { getUserApprovalStatusApi } from '../js/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './pending.css';

const PendingUsersPage = () => {
  const [isApproved, setIsApproved] = useState(false);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const checkApprovalStatus = async () => {
      try {
        const { approvalStatus } = await getUserApprovalStatusApi();
        console.log("approve status++==", approvalStatus)
        setIsApproved(approvalStatus);
      } catch (error) {
        console.error('Error checking approval status:', error.message);
      }
    };

    checkApprovalStatus();
  }, []);

  useEffect(() => {
    console.log('isApproved:', isApproved);
  }, [isApproved]);

  return (
    <div className="pending-users-container">
      <h1>Your are in Pending Users List</h1>
      <p>Your account is pending approval from the admin. Please wait for confirmation.</p>
      <p>Once you receive the approval email, please click on the 'Go Back' arrow button, return to the login page, and re-enter your email and password.</p>    
      <div className="go-back-arrow" onClick={handleGoBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span> Go Back</span>
      </div>
      <p>and enter email and password again in the login page</p>
      {isApproved && (
        <NavLink to="/bookings">
          <button className="go-to-booking-button">Go to Booking</button>
        </NavLink>
      )}
    </div>
  );
};

export default PendingUsersPage;
