import React, { useState, useEffect } from 'react';
import { FiX, FiUsers } from 'react-icons/fi';
import { useSwipeable } from 'react-swipeable';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import photo from '../Assets/Avatar.svg';
import photos from '../Assets/EntroN.svg';
import bookings from '../Assets/Booking.svg';
import connect from '../Assets/Connection.svg';
import device from '../Assets/Device.svg';
import event from '../Assets/events.png';
import home from '../Assets/Dashboard.svg';
import help from '../Assets/Help.svg';
import company from '../Assets/Company.svg';
import menu from '../Assets/menu.svg';
import action from '../Assets/ActionN.svg';
import logout from '../Assets/logout.svg';
import keypad from '../Assets/keypad.svg';

const Navbar = ({isMenuOpen, setIsMenuOpen}) => {
  const [activeTab, setActiveTab] = useState('');

  const [userData, setUserData] = useState(null);
  const storedRole = localStorage.getItem('role');

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    console.log(userData)
  };

  const getDynamicStyle = (isActive) => ({
    backgroundColor: isActive ? '#349999' : 'transparent',
    justifyContent: isMenuOpen ? 'initial' : 'center',
    transition: 'background-color 0.3s ease, justify-content 0.3s ease',
  });

  const hoverStyle = {
    backgroundColor: '#1a202c',
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  
  return (
    <div {...handlers} className={`text-white p-5 ${isMenuOpen ? 'fixed top-10% left-0 h-full w-10/11 overflow-y-auto' : ''}`} style={{ backgroundColor: '#010808', zIndex: 1000 }}>
      <div className="flex flex-row items-center justify-between pl-3">
          <Link to="/dashboard"
                onClick={() => {
                  handleTabChange('Dashboard')
                  closeMenu()
                }}>
            <div
              className={`rectangle-full object-cover `}
              style={{ justifyContent: isMenuOpen ? 'initial' : 'center' }}
            >
              <img src={photos} alt="User" />
            </div>
          </Link>
        
          <div className="cursor-pointer pr-4 text-white" onClick={toggleMenu}>
            {isMenuOpen ? (
              <FiX size={28} />
            ) : (
              <div 
                className=" text-white">
                <img
                  src={menu}
                  alt="User"
                  className="rectangle-full object-cover"
                />
              </div>
            )}
          </div>
      </div>

      {isMenuOpen && (
        <div className="flex flex-col mt-12">
        {storedRole === 'System Admin' && (
          <>
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-2 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Dashboard' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Dashboard')}
                to='/dashboard'
                title="Go to Dashboard"
                onClick={() => {
                  handleTabChange('Dashboard')
                  closeMenu()
                }}
                
              > 
                <img
                  src={home}
                  alt="User"
                  className=""
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Dashboard</span>
              </Link>

              <Link
                to="/booking"
                title="Go to Booking"
                onClick={() => {
                  handleTabChange('Booking');
                  closeMenu();
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Booking' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Booking')}
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white transition-colors duration-300"
              >
                <img
                  src={bookings}
                  alt="Booking Icon"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Bookings</span>
              </Link>

              <Link
                  to="/keypad"
                  title="Go to keypad"
                  onClick={() => {
                    handleTabChange('KeyPad');
                    closeMenu();
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'KeyPad' ? '#349999' : 'transparent'}
                  style={getDynamicStyle(activeTab === 'KeyPad')}
                  className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white transition-colors duration-300"
                  >
                  <img
                    src={keypad}
                    alt="Booking Icon"
                    className="rectangle-full object-cover"
                  />
                  <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>KeyPad</span>
              </Link>

              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Events' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Events')}
                to="/events" 
                title="Go to Events"
                onClick={() => {
                  handleTabChange('Events');
                  closeMenu();
                }}
              >
                <img
                  src={event}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Events</span>
              </Link>
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Action' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Action')}
                to="/actions" 
                title="Go to action"
                onClick={() => {
                  handleTabChange('Action');
                  closeMenu();
                }}
                >
                <img
                  src={action}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Actions</span>
              </Link>
              
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Connections' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Connections')}
                to="/connections" 
                title="Go to Connections"
                onClick={() => {
                  handleTabChange('Connections');
                  closeMenu();
                }}
              >
                <img
                  src={connect}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Connections</span>
              </Link>
      
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Devices' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Devices')}
                to="/devices" 
                title="Go to Devices"
                onClick={() => {
                  handleTabChange('Devices');
                  closeMenu();
                }}
              >
                <img
                  src={device}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Devices</span>
              </Link>
      
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Companies' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Companies')}
                to="/companies" 
                title="Go to Companies"
                onClick={() => {
                  handleTabChange('Companies');
                  closeMenu();
                }}
              >
                <img
                  src={company}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Companies</span>
              </Link>
      
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-12 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Users' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Users')}
                to="/users" 
                title="Go to New Users"
                onClick={() => {
                  handleTabChange('Users');
                  closeMenu();
                }}
              >
                <FiUsers className=" text-white" size={24} />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Users</span>
              </Link>

              <Link 
                className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Logout' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Logout')} 
                to="/login" 
                title="Go to Logout"
                onClick={handleLogout}
              >
                <img
                  src={logout}
                  alt="User"
                  className=""
                />  
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Logout</span>
              </Link>
      
              <Link 
                className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Help' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Help')}
                to="/help" 
                title="Go to Help"
                onClick={() => {
                  handleTabChange('Help');
                  closeMenu();
                }}
              >
                <img
                  src={help}
                  alt="User"
                  className=""
                /> 
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px', letterSpacing:'0%'}}>Help</span>
              </Link>

              <div className= {`w-full border-b-2 mt-12 border-gray-700 ${isMenuOpen ? 'block' : 'hidden'}`} ></div>

              <Link
                className="flex items-center justify-center gap-3 py-3 px-1 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Profile' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Profile')}  
                to="/profile"
                title="Go to Profile"
                onClick={closeMenu}
              >
                {userData && userData.image && (
                  <img
                    src={userData.image}
                    alt="User"
                    className="h-10 w-10"
                  />
                )}
                <div className={`flex flex-col items-center ${isMenuOpen ? 'block' : 'hidden'}`}>
                  {userData && (
                    <>
                      <span>{userData.firstName} {userData.lastName}</span>
                      <Link>{userData.email}</Link>
                    </>
                  )}
                </div>
              </Link>
          </>
        )}
         {(storedRole === 'Company User' || storedRole === "Company Admin") && (
            <>
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-2 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Dashboard' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Dashboard')}
                to='/dashboard'
                title="Go to Dashboard"
                onClick={() => {
                  handleTabChange('Dashboard')
                  closeMenu()
                }}
                
              > 
                <img
                  src={home}
                  alt="User"
                  className=""
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Dashboard</span>
              </Link>
        
              <Link
                to="/booking"
                title="Go to Booking"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Booking' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Booking')}
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white transition-colors duration-300"
                onClick={() => {
                  handleTabChange('Booking');
                  closeMenu();
                }}
              >

                <img
                  src={bookings}
                  alt="Booking Icon"
                  className="rectangle-full object-cover"
                />
              <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Bookings</span>
              </Link>
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Events' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Events')}
                to="/events" 
                title="Go to Events"
                onClick={() => {
                  handleTabChange('Events');
                  closeMenu();
                }}
              >
                <img
                  src={event}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Events</span>
              </Link>
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Action' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Action')}
                to="/actions" 
                title="Go to action"
                onClick={() => {
                  handleTabChange('Action');
                  closeMenu();
                }}
              >
              <img
                src={action}
                alt="User"
                className="rectangle-full object-cover"
              />
              <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Actions</span>
              </Link>

              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Devices' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Devices')}
                to="/devices" 
                title="Go to Devices"
                onClick={() => {
                  handleTabChange('Devices');
                  closeMenu();
                }}
              >
                <img
                  src={device}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Devices</span>
              </Link>
              {storedRole === "Company Admin" && (
                <Link 
                  className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Companies' ? '#349999' : 'transparent'}
                  style={getDynamicStyle(activeTab === 'Companies')}
                  to="/companies" 
                  title="Go to Companies"
                  onClick={() => {
                    handleTabChange('Companies');
                    closeMenu();
                  }}
                >
                <img
                  src={company}
                  alt="User"
                  className="rectangle-full object-cover"
                />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Companies</span>
                </Link>
              )}
            
              <Link 
                className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-20 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Users' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Users')}
                to="/users" 
                title="Go to New Users"
                onClick={() => {
                  handleTabChange('Users');
                  closeMenu();
                }}
              >
                <FiUsers className=" text-white" size={24} />
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Users</span>
              </Link>

              <Link 
                className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Logout' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Logout')} 
                to="/login" 
                title="Go to Logout"
                onClick={handleLogout}
              >
                <img
                  src={logout}
                  alt="User"
                  className=""
                />  
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Logout</span>
              </Link>

              <Link 
                className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Help' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Help')}
                to="/help" 
                title="Go to Help"
                onClick={() => {
                  handleTabChange('Help');
                  closeMenu();
                }}
              >
                <img
                  src={help}
                  alt="User"
                  className=""
                /> 
                <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px', letterSpacing:'0%'}}>Help</span>
              </Link>
              <div className= {`w-full border-b-2 mt-12 border-gray-700 ${isMenuOpen ? 'block' : 'hidden'}`} ></div>
              <Link
                className="flex items-center justify-center gap-3 py-3 px-1 mb-3 text-white"
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Profile' ? '#349999' : 'transparent'}
                style={getDynamicStyle(activeTab === 'Profile')}  
                to="/profile"
                title="Go to Profile"
                onClick={() => {
                  handleTabChange('Profile');
                  closeMenu();
                }}
              >
                {userData && userData.image && (
                  <img
                    src={userData.image}
                    alt="User"
                    className="h-10 w-10"
                  />
                )}
                <div className={`flex flex-col items-center ${isMenuOpen ? 'block' : 'hidden'}`}>
                  {userData && (
                    <>
                      <span>{userData.firstName} {userData.lastName}</span>
                      <Link>{userData.email}</Link>
                    </>
                  )}
                </div>
              </Link>
          </>
        )}

        </div>
      )}
    </div>
  );
};

export default Navbar;
