import { combineReducers, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import events from './events';
import bookings from './bookings';
import codes from './codes';
import { SET_USER } from '../actions';
import { RESET_USER } from '../actions';
import login from './login';

const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
};

const logout = (state = null, action) => {
  switch (action.type) {
    case RESET_USER:
      return null;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  events,
  bookings,
  codes,
  user,
  logout,
  login
});

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;