import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import iconL from '../../Assets/left.svg';

const ResetPasswordSuccess = () => {
  const history = useHistory();

  const handleOpenEmailApplication = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-sm p-4">
        <h1 className="text-3xl mb-6 text-black font-bold text-center items-center justify-center">Check your email!</h1>
        <p className="text-black text-center items-center justify-center">
          We sent a password reset link to your email address.
        </p>
        <button
          className="w-full mt-6 bg-black text-white py-2"
          onClick={handleOpenEmailApplication}
        >
          Open Email Application
        </button>

        <p className="text-gray-800 pt-8 flex items-center justify-center">
          Didn't receive the email?&nbsp;
          <Link to="/login" className="hover:underline">
            Click to Resend
          </Link>
        </p>

        <div className="flex items-center justify-center mt-8">
          <Link to="/login" className="flex items-center justify-center text-black hover:underline">
            <img src={iconL} alt="User" className="mr-2" /> Back to Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
