
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FaHome, FaArrowRight, FaPlus, FaMinus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FaTimes } from 'react-icons/fa';
import icon from '../Assets/iconS.svg';
import divider from '../Assets/Divider.svg';

const Profile = ({setIsMenuOpen}) => {
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);
  const [showMore6, setShowMore6] = useState(false);
  const [showMore7, setShowMore7] = useState(false);
  const [showMore8, setShowMore8] = useState(false);
  // Add more state variables for each "show more" section if needed

  const toggleShowMore1 = () => {
    setShowMore1(!showMore1);
  };

  const toggleShowMore2 = () => {
    setShowMore2(!showMore2);
  };

  const toggleShowMore3 = () => {
    setShowMore3(!showMore3);
  };

  // const toggleShowMore4 = () => {
  //   setShowMore4(!showMore4);
  // };

  // const toggleShowMore5 = () => {
  //   setShowMore5(!showMore5);
  // };

  // const toggleShowMore6 = () => {
  //   setShowMore6(!showMore6);
  // };

  // const toggleShowMore7 = () => {
  //   setShowMore7(!showMore7);
  // };

  // const toggleShowMore8 = () => {
  //   setShowMore8(!showMore8);
  // };

  const closeModal = () => {
    // Handle your close modal logic here
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div {...handlers} className="pt-0 md:pt-8 pr-8 pl-8">
      <div className='text-black md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden'>
      <img
          src={icon}
          alt="User"
          className=""
        />
        <FontAwesomeIcon icon={faChevronRight} className="" />
        {/* <FaDownload size={16} /> */}
        <span className='text-black font-bold'>Help</span>
      </div>

      <div className="bg-gray-100 pt-0 md:pt-8 md:mt-0 text-black flex flex-col items-start lg:flex-row lg:items-center lg:justify-between md:flex-row md:items-center md:justify-between">
        <div className="flex flex-col mb-6">
          <span className="font-bold" style={{fontSize:'36px', color:'010808', lineHeight:'44px', letterSpacing:'2%'}}>Help/FAQ</span>
          <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Some information about Entro.</p>
        </div>
      </div>

      <div className=''>
        <div className=''>
          <div className="flex flex-row mb-6 mt-8">
            <div style={{ maxWidth: '640px' }}>
              <span onClick={toggleShowMore1} className="cursor-pointer text-xl text-black font-bold mr-0">Who is Entro for?</span>
              {showMore1 && (
                <p className='text-gray-600'> Entro is built for private entreprenours and their companies. It is meant to automate access control in a secure, yet flexible manner.</p>
              )}
            </div>
            {/* <FaPlus
              onClick={toggleShowMore1}
              className='cursor-pointer text-gray-500 hover:text-gray-700'
              size={24}
            /> */}
          </div>
          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          />
          {/* <div className="w-full border-b-2 mb-6 border-gray-300"></div> */}

          <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore2 ? 'flex-col' : ''}`}>
            <div style={{ maxWidth: '640px' }}>
              <span onClick={toggleShowMore2} className='cursor-pointer text-xl  text-black pr-24 font-bold'>Can I invite my friends to Entro?</span>
              {showMore2 && (
                <p className='text-gray-600'>You sure can tell your friends about Entro. But Entro is not open for public to play around with. Entro has its own Entro box which is required before you can start using Entro.</p>
              )}
            </div>
            {/* <div>
              {showMore2 ? (
                <FaMinus
                  onClick={toggleShowMore2}
                  className='cursor-pointer position-fixed text-gray-500 hover:text-gray-700'
                  size={20}
                />
              ) : (
                <FaPlus
                  onClick={toggleShowMore2}
                  className='cursor-pointer text-gray-500 hover:text-gray-700'
                  size={20}
                />
              )}
            </div> */}
          </div>

          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          />

          {/* <div className="w-full border-b-2 mb-6 border-gray-300"></div> */}

          <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore3 ? 'flex-col' : ''}`}>
            <div style={{ maxWidth: '640px' }}>
              <span onClick={toggleShowMore3} className='cursor-pointer text-xl text-black pr-24 font-bold'>I have a problems, what can I do?</span>
              {showMore3 && (
                <p className='text-gray-600 w-full'>Entro is still in
                active development. Therefore you may experience issues every now and then. In
                such a case, feel free to send e-mail to entro@entro.is and the team will get right
                back to you.</p>
              )}
            </div>
            {/* <div>
              {showMore3 ? (
                <FaMinus
                  onClick={toggleShowMore3}
                  className='cursor-pointer position-fixed text-gray-500 hover:text-gray-700'
                  size={20}
                />
              ) : (
                <FaPlus
                  onClick={toggleShowMore3}
                  className='cursor-pointer text-gray-500 hover:text-gray-700'
                  size={20}
                />
              )}
            </div> */}
          </div>
          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          />
          {/* <div className="w-full border-b-2 mb-6 border-gray-300"></div> */}

          {/* <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore4 ? 'flex-col' : ''}`}>
            <div>
              <span onClick={toggleShowMore4} className='cursor-pointer text-black pr-20 font-bold'>what is cancellation policy?</span>
              {showMore4 && (
                <p className='text-gray-600 w-full lg:w-1/2'>Yes, you can change your plan at any time. Just go to your account settings and select the plan that fits your needs.</p>
              )}
            </div>
          </div> */}
          {/* <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          /> */}

          {/* <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore5 ? 'flex-col' : ''}`}>
            <div>
              <span onClick={toggleShowMore5} className='cursor-pointer text-black pr-24 font-bold'>Can other info be added?</span>
              {showMore5 && (
                <p className='text-gray-600 w-full lg:w-1/2'>Yes, you can change your plan at any time. Just go to your account settings and select the plan that fits your needs.</p>
              )}
            </div>
          </div>

          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          /> */}

          {/* <div className="w-full border-b-2 mb-6 border-gray-300"></div> */}

          {/* <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore6 ? 'flex-col' : ''}`}>
            <div>
              <span onClick={toggleShowMore6} className='cursor-pointer text-black pr-24 font-bold'>How does billing work?</span>
              {showMore6 && (
                <p className='text-gray-600 w-full lg:w-1/2'>Yes, you can change your plan at any time. Just go to your account settings and select the plan that fits your needs.</p>
              )}
            </div>
          </div>
          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          /> */}
          {/* <div className="w-full border-b-2 mb-6 border-gray-300"></div> */}

          {/* <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore7 ? 'flex-col' : ''}`}>
            <div>
              <span onClick={toggleShowMore7} className='cursor-pointer text-black pr-24 font-bold'>Can I change plan Later?</span>
              {showMore7 && (
                <p className='text-gray-600 w-full lg:w-1/2'>Yes, you can change your plan at any time. Just go to your account settings and select the plan that fits your needs.</p>
              )}
            </div>
          </div>

          <img
            src={divider}
            alt="User"
            className="w-full mb-6"
          /> */}

          {/* <div className={`flex flex-row lg:flex-row gap-3 lg:gap-48 mb-6 ${showMore8 ? 'flex-col' : ''}`}>
            <div>
              <span onClick={toggleShowMore8} className='cursor-pointer text-black pr-24 font-bold'>Can I change plan Later?</span>
              {showMore8 && (
                <p className='text-gray-600 w-full lg:w-1/2'>Yes, you can change your plan at any time. Just go to your account settings and select the plan that fits your needs.</p>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;

