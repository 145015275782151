import React, { useState, useEffect } from 'react';
import close from '../Assets/close.svg';
import avator2 from '../Assets/avator3.png';

const UsersForm = ({ closeModal, editMode, userData, onConnectionUpdated,updateTableDataAdd, userDatas }) => {
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [fileValid, setFileValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState(" ");
  const [formErrors, setFormErrors] = useState({});
  const [gradientHeight, setGradientHeight] = useState('');
  const roles = ['None', 'System Admin', 'Company User', "Company Admin"];

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '10%' : '14%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  const canEditPassword = () => {
    if (userDatas.role === "System Admin") {
      return true;
    } else if (userDatas.role === "Company User" && userDetails.username === userDatas.username) {
      return true;
    } else if (userDatas.role === "Company Admin" && userDetails.username === userDatas.username) {
    return true;
  } 
    return false;
  };  
  
  const [companies, setCompanies] = useState([{ company: 'None', CompanyID: null }]);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('https://eunitstest.onrender.com/api/getcompanydetails');
        if (response.ok) {
          const data = await response.json();
          setCompanies([{ company: 'None', CompanyID: null }, ...data]);
        } else {
          console.error('Failed to fetch companies. HTTP status:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchCompanies();
  }, []);

  const [userDetails, setUserDetails] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    eConformation: false,
    image: null,
    company:'',
    password:''
  });

  const validateForm = () => {
    const errors = {};
    if (!userDetails.username?.trim()) errors.username = 'username is required.';
    if (!userDetails.password?.trim()) errors.password = 'Password is required.';
    if (!userDetails.email?.trim || !userDetails.email.includes('@')) errors.ContactEmail = 'Valid Contact Email is required.';
    if (!userDetails.firstName?.trim()) errors.firstName = 'First Name is required.';
    if (!userDetails.lastName?.trim()) errors.lastName = 'Last Name is required.';
    if (!userDetails.role?.trim()) errors.role = 'role is required.';

    if (!userDetails.company) {
      errors.company = 'Company selection is required.';
    }

    if (!userDetails.email || !/\S+@\S+\.\S+/.test(userDetails.email)) {
      errors.email = 'Valid email is required.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [selectedRole, setSelectedRole] = useState(userDetails.role || '');

  useEffect(() => {
    if (editMode && userData) {
      setUserDetails(prevDetails => ({
        ...prevDetails,
        userId: userData.userId || '',
        username: userData.username || '',
        email: userData.email || '',
        password:userData.password || '',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        role: userData.role || '',
        company: userData.company || '',
        image: userData.image || avator2
      }));
      setImagePreview(userData.image || avator2);
    } else if (userDatas && userDatas.role === 'Company User') {
      setUserDetails(prevDetails => ({
        ...prevDetails,
        role: 'Company User',
        company: userDatas.company || ''
      }));
    }
  }, [editMode, userData, userDatas]);
  
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const updatedValue = name === 'company' ? value || 'Noona' : value;

    if (name === 'password' && !canEditPassword()) {
      return;
    }
  
    if (name === 'role') {
      setSelectedRole(value);
      setUserDetails(prevDetails => ({
        ...prevDetails,
        role: value,
        company: value === 'Company User' ? userDatas.company : prevDetails.company,
      }));
    } else if (name === 'company' && selectedRole !== 'Company User') {
      setUserDetails(prevDetails => ({
        ...prevDetails,
        company: value
      }));

    } else if (name === 'image') {
      const file = files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.height > 800) {
          setFileValid(false);
          setErrorMessage("Please upload a JPG/SVG/PNG file with a width and a height less than or equal to 800 pixels.");
          setImagePreview(null);
          return;
        } else {
          setFileValid(true);
          setErrorMessage("");
        }
        const reader = new FileReader();
        reader.onload = () => {
          setUserDetails((prevData) => ({
            ...prevData,
            image: reader.result
          }));
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      };
    } else {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: type === 'checkbox' ? checked : updatedValue,
      }));
    }

    if (name === 'role') {
      setSelectedRole(value);
  
      if (value === 'System Admin') {
        setUserDetails((prevDetails) => ({ ...prevDetails, company: 'Entro' }));
      } else {
        setCompanies(companies);
      }
    }
  };
  
  const handleAddUser = async () => {
    try {
      console.log('Sending data to API:', userDetails);
      const response = await fetch('https://eunitstest.onrender.com/api/adduser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
      });
  
      if (response.status === 200) {
        const data = await response.json();
        console.log('Response from server:', data);
        const newUser = data.result[0];
        updateTableDataAdd(newUser);
        setRegistrationStatus('success')
  
        console.log('User added successfully with userId:', newUser.userId);
      } else {
        console.error('Failed to add user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleUpdateUser = async () => {
    try {
      let apiUrl = 'https://eunitstest.onrender.com/api/updateuser';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.msg === 'user details updated') {
          setUpdateStatus('success');
          onConnectionUpdated(userDetails);
          
          console.log('User details updated successfully!');
        } else {
          console.error('Failed to update user details:', data.msg);
        }
      } else {
        console.error('Failed to update user details. HTTP status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleSave = () => {
    if (!validateForm()) {
      console.error('Validation failed.');
      return;
    }

    if (editMode) {
      handleUpdateUser();
    } else {
      handleAddUser();
    }
  };

  const handleCancel = () => {
    setUserDetails({
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      role: '',
      emailConfirmation: false,
      image: null,
      company: '',
    });
    closeModal();
  };

  return (
    <div className="fixed inset-0 flex md:items-center items-start pt-14 pb-2 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
      <div 
        className="max-w-sm md:max-w-xl p-6 pt-4"
        style={{
          background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
      
      <div className="flex flex-row gap-2 items-start justify-between pb-10">
          <div className='flex flex-col'>
            <h2 className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Edit user' : 'New user'}</h2>
            <span className='text-gray-600'>{editMode ? 'Edit Existing Users by Changing the information' : 'Please add user information below'}</span>
          </div>
          <img
            onClick={closeModal}
            src={close}
            alt="User"
            className="cursor-pointer mt-4"
          />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-2">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Update failed. Please try again.
          </div>
        )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-2">
        <div>
          <label htmlFor="firstName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            First Name <span className="text-red-500">*</span>
          </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder='None'
              value={userDetails.firstName}
              onChange={handleChange}
              className={`${formErrors.firstName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
            />
              {formErrors.firstName && (
                <p className="text-red-500 text-xs italic">{formErrors.firstName}</p>
              )}
        </div>
        <div>
          <label htmlFor="lastName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            Last Name <span className="text-red-500">*</span>
          </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder='None'
              value={userDetails.lastName}
              onChange={handleChange}
              className={`${formErrors.lastName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
            />
              {formErrors.lastName && (
                <p className="text-red-500 text-xs italic">{formErrors.lastName}</p>
              )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
        <div>
          <label htmlFor="username" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            User Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder='None'
            value={userDetails.username}
            onChange={handleChange}
            className={`${formErrors.username ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
            />
              {formErrors.username && (
                <p className="text-red-500 text-xs italic">{formErrors.username}</p>
              )}
        </div>
       
        <div className="mb-2">
          <label htmlFor="email" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='None'
            value={userDetails.email}
            onChange={handleChange}
            className={`${formErrors.email ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
            />
              {formErrors.email && (
                <p className="text-red-500 text-xs italic">{formErrors.email}</p>
              )}
        </div>

        <div className="mb-2">
          <label htmlFor="password" className="text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder='None'
            value={userDetails.password}
            onChange={handleChange}
            disabled={!canEditPassword()}
            className={`${formErrors.password ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
            />
              {formErrors.password && (
                <p className="text-red-500 text-xs italic">{formErrors.password}</p>
              )}
        </div>


        <div className="mb-2">
          <label htmlFor="role" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            Role <span className="text-red-500">*</span>
          </label>
          <select
            id="role"
            name="role"
            value={userDetails.role}
            onChange={handleChange}
            className={`${formErrors.role ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
          >
            {roles
              .filter(role => userDatas && userDatas.role === "Company User" ? role === "Company User" : true)
              .map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
            ))}
            </select>
            {formErrors.role && (
                <p className="text-red-500 text-xs italic">{formErrors.role}</p>
              )}
        </div>
      </div>

      {userDatas && userDatas.role === 'System Admin' && (
        <div className="mb-2">
          <label htmlFor="role" className="text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
            Company <span className="text-red-500">*</span>
          </label>
          <select
            id="company"
            name="company"
            placeholder='None'
            value={userDetails.company}
            onChange={handleChange}
            className={`mt-1 w-full px-2 py-1.5 text-gray-600 border focus:outline-none ${formErrors.company ? 'border-red-500' : ''} ${selectedRole === 'System Admin' ? 'opacity-50' : ''}`}
            disabled={selectedRole === 'System Admin'}
          >
            {companies.map((company) => (
              <option key={company.CompanyID} value={company.company}>
                {company.company}
              </option>
            ))}
            
          </select>
          {formErrors.company && (
              <p className="text-red-500 text-xs italic">{formErrors.company}</p>
          )}
        </div>
      )}

      <div className="mb-4 mt-4">
        <label className="text-gray-600">
          Profile Picture
        </label>

        {!fileValid && (
          <p className="text-red-500 py-5 text-md">{errorMessage}</p>
        )}
        <h2 className='text-gray-500 mt-2'>Please upload a JPG/SVG/PNG file with a width and a height less than or equal to 800 pixels.</h2>
        <div className="flex items-center">
          {imagePreview && <img src={imagePreview} alt="Preview" className="shadow-md mr-4 h-12 w-16" />}
          <input
            type="file"
            placeholder='Please upload a JPG file with a width of 400 pixels and a height less than 400 pixels.'
            id="image"
            name="image"
            accept="image/*"
            onChange={handleChange}
            className="mt-1 w-full md:px-44 px-28 py-6 border focus:outline-none"
          />
        </div>
      </div>

      <div className='flex flex-row gap-2 items-center mb-2'>
        <input
          type="checkbox"
          id="rememberMe"
          className=""
          checked={userDetails.eConformation}
          onChange={() => setUserDetails((prevDetails) => ({ ...prevDetails, eConformation: !prevDetails.eConformation }))}
        />
        <label htmlFor="rememberMe" className=" text-md mb-4 mt-4" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
          Send Email confirmation
        </label>
      </div>

      <div className="flex md:flex-row md:justify-end flex flex-col pt-2 gap-3">
        <button
          onClick={handleCancel}
          className="border text-gray-600 px-3 py-2"
        >
          Discard
        </button>

        <button
          onClick={handleSave}
          className={`bg-black border text-gray-100 px-3 py-2`}
        >
          {editMode ? 'Save changes' : 'Add user'}
        </button>
      </div>
     </div>
    </div>
  );
};

export default UsersForm;
