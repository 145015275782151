import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import './styles.css';
import close from '../Assets/close.svg';

const NewBookingForm = ({ closeModal, onNewBookingAdded, editMode, bookingData, updateTableDataAdd, onBookingUpdated }) => {

  const [bookingEndDate, setBookingEndDate] = useState('');
  const [valid, setValid] = useState(false);
  const [formMode, setFormMode] = useState('single');
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [companies, setCompanies] = useState([{ company: 'None', CompanyID: null }]);
  const [formData, setFormData] = useState({
    bookingCustomerName: '',
    bookingCustomerPhone: '',
    bookingCode: '',
    bookingStartDate: '',
    bookingStartsAtTime: '',
    bookingEndDate: '',
    bookingEndsAtTime: '',
    bookingCompanyEmail:'',
    // eConformationEmail: false,
    eConformationText: 'false',
  });

  const [gradientHeight, setGradientHeight] = useState('');

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '10.7%' : '15%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  let companyID;
  if (userData && (userData.role === 'Company User'|| userData.role ==='Company Admin')) {
    const companyDetail = companies.find(c => c.company === userData.company);
    companyID = companyDetail ? companyDetail.company_ID : null;
  } else {
    const selectedCompany = companies.find(company => company.company === formData.company);
    companyID = selectedCompany ? selectedCompany.company_ID : null;
  }

  const handleChangeCheckbox = (e) => {
    const { id, checked } = e.target;
    setFormData(prevData => ({
        ...prevData,
        [id]: checked ? "true" : "false"
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.bookingCustomerName.trim()) errors.bookingCustomerName = 'Name is required.';
    // if (!formData.bookingCustomerPhone.trim()) errors.bookingCustomerPhone = 'Phone is required.';
    if (formData.bookingCode.length !== 6) errors.bookingCode = 'Code must be 6 characters long.';
    if (!formData.bookingStartDate.trim()) errors.bookingStartDate = 'Start date is required.';
    if (!formData.bookingEndDate.trim()) errors.bookingEndDate = 'End date is required.';
    // if (!formData.bookingCompanyEmail.trim()) errors.bookingCompanyEmail = 'Email is required.';
    if (!formData.bookingStartsAtTime.trim())errors.bookingStartsAtTime = 'Start time is required';
    if (!formData.bookingEndsAtTime.trim())errors.bookingEndsAtTime = 'End time is required';

    if (userData && userData.role !== 'Company User' && !formData.company) {
      errors.company = 'Company is required';
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    async function fetchCompanies() {
        try {
            const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
            if (response.status === 200) {
                setCompanies([{ company: 'None', CompanyID: null }, ...response.data]);
            }
        } catch (error) {
            console.error('Failed to fetch companies:', error);
        }
    }
    fetchCompanies();

    // async function fetchConnections() {
    //     try {
    //         const response = await axios.get('https://eunitstest.onrender.com/api/getconnectiondetails');
    //         if (response.status === 200) {
    //             setConnections([{ Connection: 'None', connectionID: null }, ...response.data.map(c => ({ Connection: c.Connection || 'None', connectionID: c.id }))]);
    //         }
    //     } catch (error) {
    //         console.error('Failed to fetch connections:', error);
    //     }
    // }
    // fetchConnections();
  }, []);

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
        if (response.status === 200) {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    }
    fetchCompanies();
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'company') {
      const selectedCompany = companies.find(company => company.company === value);
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        company_ID: selectedCompany ? selectedCompany.company_ID : '',
      }));
    } 
    // else if (name === 'Connection') {
    //   // setFormData(prevData => ({
    //   //   ...prevData,
    //   //   [name]: value,
    //   // }));
    // } 
    else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function formatDate(dateString) {
    if (!dateString) return '';
    
    const parts = dateString.split('/');
    if (parts.length === 3) {
        return `${parts[0]}-${parts[1]}-${parts[2]}`;
    }
    return dateString;
  }

  useEffect(() => {
    if (editMode && bookingData) {
        setFormData({
            bookingCustomerName: bookingData.bookingCustomerName || '',
            bookingCustomerPhone: bookingData.bookingCustomerPhone || '',
            bookingCode: bookingData.bookingCode || '',
            bookingStartDate: bookingData.bookingStartDate ? formatDate(bookingData.bookingStartDate) : '',
            bookingStartsAtTime: bookingData.bookingStartsAtTime || '',
            bookingEndDate: bookingData.bookingEndDate ? formatDate(bookingData.bookingEndDate) : '',
            bookingEndsAtTime: bookingData.bookingEndsAtTime || '',
            bookingCompanyEmail: bookingData.bookingCompanyEmail || '',
            Connection: bookingData.Connection || 'None',
            company: bookingData.company || 'None',
            company_ID: bookingData.company_ID || null
        });
    }
  }, [editMode, bookingData]);

  useEffect(() => {
    setValid(
      formData.bookingCustomerName &&
      formData.bookingCustomerPhone &&
      formData.bookingStartsAtTime &&
      formData.bookingEndsAtTime &&
      formData.bookingCode?.length === 6 &&
      formData.bookingStartDate &&
      formData.bookingCompanyEmail &&
      formData.bookingEndDate
    );
  }, [formData]);
  
  useEffect(() => {
    if (formMode === 'single') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail
      );
    } else if (formMode === 'recurring') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail &&
        formData.company
      );
    }
  }, [formMode, formData]);

  const toggleFormMode = (mode) => {
    setFormMode(mode);
  };
  
  const handleSave = async () => {
    if (!validateForm()) {
      console.error('Validation failed.');
      return;
    }

    if (editMode) {
        handleUpdateBooking();
    } else {
        const bookingDetails = {
          ...formData,
          userId: userData?.userId,
          company: userData?.role === 'Company User' ? userData.company : formData.company,
          company_ID: companyID,
          bookingStartsAtTime: Math.floor(new Date(`${formData.bookingStartDate}T${formData.bookingStartsAtTime}:00Z`).getTime() / 1000),
          bookingEndsAtTime: Math.floor(new Date(`${formData.bookingEndDate}T${formData.bookingEndsAtTime}:00Z`).getTime() / 1000),
          Integration: "web",
          status: "notdone",
          eConformationText: formData.eConformationText,
        };
        try {
            const response = await axios.post('https://eunitstest.onrender.com/api/addbooking', bookingDetails);
            if (response.status === 200) {
                updateTableDataAdd(response.data.result);
                setRegistrationStatus('success');
            } else {
                throw new Error('Failed to process booking');
            }
        } catch (error) {
            console.error('Error submitting the booking:', error);
            setRegistrationStatus('failure');
        }
    }
  };

  const handleUpdateBooking = () => {
    const baseURL = 'https://eunitstest.onrender.com/api';
    const payload = {
      bookingId: bookingData.bookingId,
      company_ID: companyID,
      ...formData,
      Integration: "web",
      status: "notdone",
      userId: userData.userId,
      eConformationText: formData.eConformationText,
    };

    fetch(`${baseURL}/updatebooking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to update booking. Status: ' + response.status);
      }
    })
    .then(data => {
      if (data.error) {
        throw new Error('Error in response data: ' + data.error);
      }
      setUpdateStatus("success");
      onBookingUpdated(data);
    })
    .catch(error => {
      console.error("Error updating booking:", error);
      setUpdateStatus("failure");
    });
  };
  
  return (
      <div className="fixed inset-0 flex md:items-center items-start pt-14 pb-2 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
        <div 
          className="max-w-sm md:max-w-xl p-6 pt-5"
          style={{
            background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
            borderBottom: '1px solid black'
          }}
        >
          <div className="flex flex-row gap-2 items-start justify-between pb-10">
            <div className='flex flex-col'>
             <span className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Update Booking' : 'New Booking'}</span>
             <span className="text-gray-600" >{editMode ? 'Update Booking by Entering the information' : 'Add your new Booking by Entering the information'} </span>
            </div>
           <img
              onClick={closeModal}
              src={close}
              alt="User"
              className="cursor-pointer mt-4 pl-10"
           />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-2">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Update failed. Please try again.
          </div>
        )}

        <div className="flex flex-col md:flex-row gap-3 mb-6">
          <button
            className={`mr-2 w-full md:w-1/2 py-2 ${formMode === 'single' ? 'text-white bg-gray-800' : 'text-gray-600 bg-gray-50'}`}
            onClick={() => toggleFormMode('single')}
          >
            Single
          </button>
          <button
            className={`w-full md:w-1/2 py-2 text-white ${formMode === 'recurring' ? 'bg-gray-800' : 'bg-gray-300'}`}
            onClick={() => toggleFormMode('recurring')}
          >
            Recurring
          </button>
        </div>

        {formMode === 'single' && (
          <form>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-6">
                <label className="text-sm font-medium text-gray-900">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="bookingCustomerName"
                  placeholder='None'
                  value={formData.bookingCustomerName}
                  onChange={handleChange}
                  className={`${formErrors.bookingCustomerName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {formErrors.bookingCustomerName && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerName}</p>
                )}
              </div>
              
              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                <label className="text-sm font-medium text-gray-900">
                  Phone 
                </label>
                <input
                  type="text"
                  name="bookingCustomerPhone"
                  value={formData.bookingCustomerPhone}
                  onChange={handleChange}
                  placeholder="None"
                  className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {/* {formErrors.bookingCustomerPhone && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerPhone}</p>
                )} */}
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                <label className="text-sm font-medium text-gray-900">
                  Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="bookingCode"
                  value={formData.bookingCode}
                  onChange={handleChange}
                  placeholder="None"
                  className={` ${formErrors.bookingCode ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {formErrors.bookingCode && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingCode}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3">  
              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                <label className="text-sm font-medium text-gray-900">Start Date <span className="text-red-500">*</span></label>
                <input
                  type="date"
                  name="bookingStartDate"
                  value={formData.bookingStartDate}
                  onChange={handleChange}
                  placeholder="Select a start date"
                  className={` ${formErrors.bookingStartDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {formErrors.bookingStartDate && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingStartDate}</p>
                )}
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>Start Time <span className="text-red-500">*</span></label>
                  <input
                    type="time"
                    name='bookingStartsAtTime'
                    placeholder='None'
                    value={formData.bookingStartsAtTime}
                    onChange={handleChange}
                    className={` ${formErrors.bookingStartsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  required
                  />

                {formErrors.bookingStartsAtTime && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingStartsAtTime}</p>
                )}
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>End Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingEndDate"
                    value={formData.bookingEndDate}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingEndDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingEndDate}</p>
                  )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2 mt-6">
              <div className="w-full md:w-1/2  px-3 mb-2">
                <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>End Time <span className="text-red-500">*</span></label>
                <input
                  type="time"
                  name='bookingEndsAtTime'
                  placeholder='None'
                  value={formData.bookingEndsAtTime}
                  onChange={handleChange}
                  className={` ${formErrors.bookingEndsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.bookingEndsAtTime && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingEndsAtTime}</p>
                )}
              </div>
                
              <div className="w-full md:w-1/2 px-3 mb-2 md:mb-6">
                <label className="text-sm font-medium text-gray-900">
                  Booking Email
                </label>
                <input
                  type="text"
                  name="bookingCompanyEmail"
                  placeholder="None"
                  value={formData.bookingCompanyEmail}
                  onChange={handleChange}
                  className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
              </div>
  
              {userData && userData.role !== 'Company User' && (
                <div className="w-full px-3 mb-2 md:mb-0">
                    <label className="text-sm font-medium text-gray-900">
                        Company <span className="text-red-500">*</span>
                    </label>
                    <select
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className={`mt-1 py-2 px-2 w-full border ${formErrors.company ? 'border-red-500' : 'border-gray-300'} text-gray-500 focus:outline-none`}
                    >
                        {companies.map((company) => (
                          <option key={company.CompanyID} value={company.company}>
                            {company.company}
                          </option>
                        ))}
                    </select>
                    {formErrors.company && (
                        <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                    )}
                </div>
              )}

              <div className='p-4 flex flex-col'>
                <label className=" text-gray-900 font-semibold pb-4">Notify user</label>
                <div className='flex flex-row gap-2 items-center mb-2'>
                <input
                  type="checkbox"
                  id="eConformationText"
                  checked={formData.eConformationText === 'true'}
                  onChange={handleChangeCheckbox}
/>
                  <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                    Text message
                  </label>
                </div>
              </div>
            </div>
          </form>
      )}

      {formMode === 'recurring' && (
        <form>
          <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/3 sm:w-1/2 px-3 mb-2 md:mb-6">
            <label htmlFor="unitId" className=" text-gray-600">
              Name
            </label>
            <input
              type="text"
              name='bookingCustomerName'
              value={formData.bookingCustomerName}
              onChange={handleChange}
              className="mt-1 p-2 w-full border text-gray-600 focus:outline-none"
              required
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
            <label htmlFor="macAddress" className=" text-gray-600">
              Phone
            </label>
            <input
              type="text"
              name='bookingCustomerPhone'
              value={formData.bookingCustomerPhone}
              onChange={handleChange}
              className="mt-1 p-2 w-full border text-gray-600 focus:outline-none"
              required
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
            <label htmlFor="macAddress" className=" text-gray-600">
              Code
            </label>
            <input
              type="text"
              name='bookingCode'
              value={formData.bookingCode}
              onChange={handleChange}
              className="mt-1 p-2 w-full border text-gray-600 focus:outline-none"
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-2 md:mb-0">
            <label className=" text-gray-600" >Start Date</label>
                <input
                  id="datepicker"
                  className="p-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                  type="date"
                  name='bookingStartDate'
                  placeholder="Date"
                  value={formData.bookingStartDate}
                  onChange={handleChange}
                />
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-2 md:mb-0">
            <label className=" text-gray-600" style={{color:'#4B5563'}}>Start Time</label>
                {/* <input
                  type="time"
                  className="p-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                  name='bookingStartsAtTime'
                  placeholder="StartTime"
                  value={formData.bookingStartsAtTime}
                  onChange={handleChange}
                /> */}

                <input
                  type="time"
                  id="office_open_minute"
                  name='bookingStartsAtTime'
                  placeholder='None'
                  value={formData.bookingStartsAtTime}
                  onChange={handleChange}
                  className="p-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                  required
                />
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-2 md:mb-0">
            <label className="text-gray-600">End Date</label>
                <input
                  id="datepicker"
                  className="p-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                  name='bookingEndDate'
                  type="date"
                  placeholder="Date"
                  value={formData.bookingEndDate}
                  onChange={handleChange}
                />
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-2 md:mb-0">
            <label className="text-gray-600">End Time</label>
                <input
                  type="time"
                  className="p-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                  placeholder="EndTime"
                  name='bookingEndsAtTime'
                  value={formData.bookingEndsAtTime}
                  onChange={handleChange}
                />
          </div>
        </div>
        <label className=" text-gray-900 pt-4">Repeats</label>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="flex flex-row gap-2 md:w-1/2 px-3 mb-2 md:mb-0 focus:outline-none">
            <p className='text-gray-900'>Repeats</p>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="mt-1 p-2 w-full border text-gray-600"
              required
            >
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </div>
          <div className="flex flex-row gap-2 md:w-1/2 px-3 mb-2 md:mb-0">
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full border px-2 text-gray-600 focus:outline-none"
              required
            />
            <label className='text-gray-600 mt-2'>Week</label>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-sm font-medium text-gray-600">Repeat on the following days</label>
            <div className="flex items-center space-x-4 mt-2">
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday'].map((day) => (
                <label key={day} className="inline-flex items-center text-gray-600">
                  <input
                    type="checkbox"
                    name={`repeat${day}`}
                    checked={formData[`repeat${day}`]}
                    onChange={(e) => handleChangeCheckbox(e, `repeat${day}`)}
                    className="form-checkbox h-5 w-5 text-gray-300"
                  />
                  <span className="ml-2">{day}</span>
                </label>
              ))}
            </div>

            <div className="flex flex-row space-x-7 mt-2">
              {['Friday', 'Saturday', 'Sunday', 'Everyday'].map((day) => (
                <label key={day} className="inline-flex items-center text-gray-600">
                  <input
                    type="checkbox"
                    name={`repeat${day}`}
                    checked={formData[`repeat${day}`]}
                    onChange={(e) => handleChangeCheckbox(e, `repeat${day}`)}
                    className="form-checkbox h-5 w-5 text-gray-600"
                    style={{
                      backgroundColor: formData[`repeat${day}`] ? '010808' : 'transparent',
                      outline: 'none',
                    }}
                  />
                  <span className="ml-2">{day}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className=" text-gray-600">Ends the following days</label>
            <div className="flex items-center space-x-4 mt-2">
              {['Never', 'After'].map((day) => (
                <label key={day} className="inline-flex items-center text-gray-600"
                >
                  <input
                    type="checkbox"
                    name={`repeat${day}`}
                    checked={formData[`repeat${day}`]}
                    onChange={(e) => handleChangeCheckbox(e, `repeat${day}`)}
                    style={{
                      backgroundColor: formData[`repeat${day}`] ? '010808' : 'transparent',
                      outline: 'none',
                    }}
                  />
                  <span className="ml-2">{day}</span>
                </label>
              ))}

            <div className="flex flex-row gap-2 md:w-1/2 px-3 mb-2 md:mb-0">
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="w-10 border text-gray-600"
                required
              />
              <label className='text-gray-600 mt-2'>Week</label>
            </div>

              <div className="md:w-1/2 mb-2 md:mb-0">
                <input
                  id="datepicker"
                  className="p-2 text-gray-600 border border-gray-300 focus:outline-none"
                  type="date"
                  value={bookingEndDate}
                  placeholder="Date"
                  onChange={(e) => setBookingEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      )}
         <div className="flex md:flex-row md:justify-end flex-col gap-2 pt-6">
            <button
              onClick={closeModal}
              className="px-3 py-2 text-gray-600 bg-white border"
            >
             Discard
            </button>
            <button
              onClick={handleSave}
              type="submit"
              className='py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300'
              style={{ backgroundColor: '#010808' }}
            >
              {editMode ? 'Save changes' : 'Add booking'}
              
            </button>
         </div>
      </div>
     </div>
   );
};

export default NewBookingForm;
