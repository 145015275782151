import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import close from '../Assets/close.svg';

const ConnectionForm = ({ closeModal, connectionData, editMode, onConnectionUpdated, updateTableData, onAddConnection }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const statusS = ['None', 'Active','Inactive','Archived'];
  const SharedSecrets = ['None', 'Zapier 1-JSON post','Zapier 2-HTTP GET']
  const [companies, setCompanies] = useState([{ company: 'None', CompanyID: null }]);
  const [formErrors, setFormErrors] = useState({});
  const [gradientHeight, setGradientHeight] = useState('');

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '23%' : '25%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.Connection.trim()) errors.Connection = 'Connection is required.';
    if (!formData.status.trim()) errors.status = 'Status is required.';
    if (!formData.SharedSecret.trim()) errors.SharedSecret = 'Integration is required.';
    if (!formData.company.trim()) errors.company = 'Company is required.';
    if (!formData.connection_key.trim()) errors.connection_key = 'Connection_key is required.';
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('https://eunitstest.onrender.com/api/getcompanydetails');
        if (response.ok) {
          const data = await response.json();
          setCompanies([{ company: 'None', CompanyID: null }, ...data]);
        } else {
          console.error('Failed to fetch companies. HTTP status:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchCompanies();
  }, []);

  const [formData, setFormData] = useState({
    Connection: '',
    Category: '',
    Status: '',
    SharedSecret: '',
    company:'',
    connection_key:''
  });

  useEffect(() => {
    if (editMode && connectionData) {
      setFormData({
        id: connectionData.Connection_ID,
        Connection: connectionData.Connection,  
        status: connectionData.status,
        SharedSecret: connectionData.SharedSecret,
        company: connectionData.company,
        connection_key:connectionData.connection_key        
      });
    } else {
      setFormData({
        Connection: '',
        status: '',
        SharedSecret: '',
        company: '',
        connection_key:''
      });
    }
  }, [connectionData, editMode]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
  
    if (name === 'company') {
      const selectedCompany = companies.find(company => company.company === value);
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        company_ID: selectedCompany ? selectedCompany.company_ID : '',
        Connection_ID: connectionData ? connectionData.Connection_ID : prevData.id,
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        image: type === 'file' ? e.target.files[0] : prevData.image,
        Connection_ID: connectionData ? connectionData.Connection_ID : prevData.id,
      }));
    }
  
    const formFields = ['Connection', 'SharedSecret', 'company'];
    const isValid = formFields.every(field => formData[field]);
    setIsFormValid(isValid);
  };
  
  const handleUpdateConnection = async () => {
    try {
      let apiUrl = 'https://eunitstest.onrender.com/api/updateconnectiondetails';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
      console.log('Response:', responseData);
  
      if (response.ok) {
        if (responseData.msg === 'Connection details updated') {
          setUpdateStatus('success');
          console.log('Connection details updated successfully!');
          onConnectionUpdated(formData);
        } else if (responseData.msg === 'Connection not exist') {
          setUpdateStatus('failure');
          console.error('Failed to update connection details: Connection does not exist');
        } else {
          setUpdateStatus('failure');
          console.error('Failed to update connection details:', responseData.msg);
        }
      } else {
        console.error('Failed to update connection details. HTTP status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (!validateForm()) {
        console.error('Validation failed.');
        return;
      }
      if (editMode) {
        await handleUpdateConnection();
      } else {
        await onAddConnection(formData);
        setRegistrationStatus('success');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex md:items-center items-start pt-14 md:pt-1 pb-2 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
      <div 
        className="max-w-sm md:max-w-lg p-6 pt-3"
        style={{
          background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
        <div className="flex flex-row gap-2 items-start justify-between pb-10">
          <div className='flex flex-col'>
            <h2 className="font-semibold" style={{fontSize:'36px', color:'#000000', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Update Connection' : 'New Connection'}</h2>
              <p className='text-gray-600'>{editMode ? 'Update Connection configuration details below' : 'Add your new Connection by filling out all required details below.'}</p>
            </div>
            <img
              onClick={closeModal}
              src={close}
              alt="User"
              className="cursor-pointer mt-4 pl-10"
            />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-4">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-4">
            Update failed. Please try again.
          </div>
        )}
        
        <form>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label htmlFor="unitId" className=" text-gray-600">
                Connection Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="Connection"
                name="Connection"
                placeholder='None'
                value={formData.Connection}
                onChange={handleChange}
                className={`${formErrors.Connection ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {formErrors.Connection && (
                  <p className="text-red-500 text-xs italic">{formErrors.Connection}</p>
                )}
            </div>
            
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="macAddress" className=" text-gray-600">
                Status <span className="text-red-500">*</span>
              </label>
              <select
                id="status"
                name="status"
                placeholder='None'
                value={formData.status}
                onChange={handleChange}
                className={`${formErrors.status ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
              >
                {statusS.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
              {formErrors.status && (
                  <p className="text-red-500 text-xs italic">{formErrors.status}</p>
                )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label htmlFor="location" className=" text-gray-600">
                Integration <span className="text-red-500">*</span>
              </label>
              <select
                id="SharedSecret"
                name="SharedSecret"
                placeholder='None'
                value={formData.SharedSecret}
                onChange={handleChange}
                className={`${formErrors.SharedSecret ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
              >
                {SharedSecrets.map((SharedSecret) => (
                  <option key={SharedSecret} value={SharedSecret}>
                    {SharedSecret}
                  </option>
                ))}
              </select>
              {formErrors.SharedSecret && (
                  <p className="text-red-500 text-xs italic">{formErrors.SharedSecret}</p>
                )}
            </div>

            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="macAddress" className=" text-gray-600">
                Company <span className="text-red-500">*</span>
              </label>
              <select
                id="company"
                name="company"
                placeholder='None'
                value={formData.company}
                onChange={handleChange}
                className={`${formErrors.company ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
              >
                {companies.map((company) => (
                  <option key={company.CompanyID} value={company.company}>
                    {company.company}
                  </option>
                ))}
              </select>
              {formErrors.company && (
                  <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                )}
            </div>
          </div>

          <div className="w-full mb-6">
              <label htmlFor="unitId" className=" text-gray-600">
                Connection Key <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="connection_key"
                name="connection_key"
                placeholder='None'
                value={formData.connection_key}
                onChange={handleChange}
                className={`${formErrors.connection_key ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                required
              />
              {formErrors.connection_key && (
                  <p className="text-red-500 text-xs italic">{formErrors.connection_key}</p>
                )}
            </div>

          <div className="flex md:flex-row md:justify-end flex-col pt-6 gap-3">
            <button
              onClick={closeModal}
              className="px-3 py-2 text-gray-600 border focus:outline-none focus:ring focus:border-gray-300"
            >
              Discard
            </button>
            <button
              onClick={handleSave}
              type="submit"
              className={`py-2 px-3 text-white bg-black hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300`}
            >
              {editMode ? 'Save changes' : 'Add Connection'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConnectionForm;
