import React, { useEffect, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Bookings from './Bookings';
import Actions from './Actions';
import Events from './Events';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Sidebar from './SideBar';
import AdminDashboard from './Admin';
import PendingUsersPage from './pendingUsers';
import SystemAdmin from './SystemAdmin';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPasswordSuccess from './Auth/ResetPasswordSucess';
import SetNewPassword from './Auth/SetNewPassword';
import ResetSuccess from './Auth/Reset';
import "@fontsource/hanken-grotesk";
import "@fontsource/hanken-grotesk/400.css";
import "@fontsource/hanken-grotesk/400-italic.css"; 

const App = () => { 

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  return (
    <div className='bg-gray-100' style={{ backgroundColor: '#010808' }}>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/signup" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password-success" component={ResetPasswordSuccess} />
        <Route path="/set-new-password/:resetToken" component={SetNewPassword} />
        <Route path="/reset-success" component={ResetSuccess} />
        <Route path="/admin-dashboard" component={AdminDashboard} />
        <Route userData={userData} path="/admin" component={SystemAdmin} />
        <Route path="/events" component={Events} />
        <Route path="/pending" component={PendingUsersPage} />
        <div style={{backgroundColor:'#010808'}}>
          <Route path='/sidebar' component={Sidebar} />
        </div> 
      </Switch>
    </div>
  );
}

export default App;
