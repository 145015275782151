import React from 'react';
import './styles.css';
import trash from '../Assets/trash.svg';
import { MdArrowUpward } from 'react-icons/md';
import DataTable from 'react-data-table-component';

const Table = ({ rows = [], onDelete, userData }) => {
  const role = userData?.role;
  const userCompany = userData?.company ? userData.company.toLowerCase().replace(/\s/g, '') : '';

  const headings = ['timestamp', "username", 'code', 'entryway', 'venue', 'company_name', 'status'];
  const displayNames = {
    timestamp: "Date & Time",
    status: "Status",
    code: 'Code',
    company_name: 'Company',
    venue: 'Venue',
    entryway: 'Entryway',
    username: 'Name'
  };

  const filteredHeadings = role === 'Company User' ? headings.filter(heading => heading !== 'company_name') : headings;

  const filteredRows = (role === 'Company User' || role === 'Company Admin')
    ? rows.filter(row => row.company_name && row.company_name.toLowerCase().replace(/\s/g, '') === userCompany)
    : rows;

  const columns = filteredHeadings.map((heading) => {
    if (heading === 'status') {
      return {
        name: displayNames[heading] || heading,
        selector: row => row[heading],
        sortable: true,
        cell: (row) => {
          const status = row.status;
          let statusColor = 'bg-red-400 text-white';

          if (['Success', 'success', 'True'].includes(status)) {
            statusColor = 'bg-green-500 text-white';
          } else if (['Fail', 'fail', 'false', 'False'].includes(status)) {
            statusColor = 'bg-red-500 text-white';
          } else if (['Fail Unknown', 'Fail Too soon', 'Fail Expired', 'Fail Incomplete'].includes(status)) {
            statusColor = 'bg-red-400 text-white';
          }

          return (
            <div className={`flex flex-row gap-2 pr-4 lg:pr-1 md:pr-12 ${statusColor}`}>
              <button className="px-2 py-1 rounded">
                {status}
              </button>
            </div>
          );
        }
      };
    } else {
      return {
        name: displayNames[heading] || heading,
        selector: row => row[heading],
        sortable: true,
        format: (row) => {
          if (heading === 'timestamp') return formatDateTime(row[heading]);
          return row[heading];
        },
        minWidth: '150px'
      };
    }
  });

  if (userData && (userData.role === "Company Admin" || userData.role === "System Admin")) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) => (
        <div className="flex flex-row gap-2 text-gray-600 pr-4 lg:pr-1 md:pr-12">
          <img src={trash} alt="Delete" className="rectangle-full w-5 h-5 object-cover" onClick={() => onDelete(row['eventId'])} />
          <button className="text-red-500 pr-4" onClick={() => onDelete(row['eventId'])} >
            Delete
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: false
    });
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '52px',
      }
    },
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '10px',
        paddingRight: '10px',
      }
    },
  };

  return (
    <div className="overflow-x-auto">
      <DataTable
        columns={columns}
        data={filteredRows}
        sortIcon={<MdArrowUpward />}
        pagination
        customStyles={customStyles}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40]}
        paginationComponentOptions={{ rowsPerPageText: 'Rows per page' }}
        noHeader
        persistTableHead
        highlightOnHover
      />
    </div>
  );
};

const formatDateTime = (dateTime) => {
  if (!dateTime) return '';
  const [date, time] = dateTime.split('T');
  return `${date} ${time.slice(0, 5)}`;
};

export default Table;
