import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import edit from '../Assets/edit-05.png';
import trash from '../Assets/trash.svg';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Modal from './Modal';
import close from '../Assets/closeP.svg';
import avator2 from '../Assets/logop.svg';

const customSortIcon = <MdArrowUpward />;

const TableCompany = ({ rows, onEditCompany, onDelete, onSettings }) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [eventCounts, setEventCounts] = useState({});
  const [activeBookings, setActiveBookings] = useState({});
  const [deviceCounts, setDeviceCounts] = useState({});
  const [smsCounts, setSMSCounts] = useState({});

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return size;
  };
  
  const [height, width] = useWindowSize();

  const handleDelete = (row) => {
    setCurrentRow(row);
    setIsModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    onDelete(currentRow.company_ID);
    setIsModalOpen(false);
  };

  function normalizeCompanyName(name) {
    if (!name) return '';
    return name.replace(/\s/g, '').toLowerCase();
  }

  const handleDeviceClick = (companyName) => {
    history.push(`/devices?filter=${encodeURIComponent(companyName)}`);
  };

  const handleEventClick = (companyName) => {
    const normalizedCompanyName = normalizeCompanyName(companyName);
    history.push(`/events?filter=${encodeURIComponent(normalizedCompanyName)}`);
  };

  const handleActiveBookingClick = (companyName) => {
    history.push(`/booking?company=${encodeURIComponent(companyName)}`);
  };

  const fetchDeviceCounts = async () => {
    try {
      const response = await axios.get('https://eunitstest.onrender.com/api/getdevice');
      const counts = {};
      response.data.forEach(device => {
        const companyId = device.company;
        if (counts[companyId]) {
          counts[companyId] += 1;
        } else {
          counts[companyId] = 1;
        }
      });
      setDeviceCounts(counts);
    } catch (error) {
      console.error('Error fetching device data:', error);
    }
  };
  
  const fetchEventCounts = async () => {
    try {
      const response = await axios.get('https://eunitstest.onrender.com/api/geteventdetails');
      console.log('API Response:', response.data);
      const counts = {};
      response.data.forEach(event => {
        const companyId = normalizeCompanyName(event.company_name);
        if (counts[companyId]) {
          counts[companyId] += 1;
        } else {
          counts[companyId] = 1;
        }
      });
      console.log('Event counts:', counts);
      setEventCounts(counts);
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  const fetchBookingCounts = async () => {
    try {
      const bookingResponse = await axios.get('https://eunitstest.onrender.com/api/getbooking');
      const now = new Date();
      const tolerance = 10 * 60 * 1000;
      const countsBooking = {};
  
      bookingResponse.data.forEach(booking => {
        const bookingStartDate = new Date(booking.bookingStartDate + ' ' + booking.bookingStartsAtTime);
        const bookingEndDate = new Date(booking.bookingEndDate + ' ' + booking.bookingEndsAtTime);
  
        if ((now.getTime() >= (bookingStartDate.getTime() - tolerance)) && (now.getTime() <= (bookingEndDate.getTime() + tolerance))) {
          const companyId = booking.company;
          if (countsBooking[companyId]) {
            countsBooking[companyId] += 1;
          } else {
            countsBooking[companyId] = 1;
          }
        }
      });
  
      setActiveBookings(countsBooking);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSMSCounts = async () => {
    try {
      const response = await axios.get('https://eunitstest.onrender.com/api/getbooking');
      const counts = {};
      
      response.data.forEach(booking => {
        if (booking.SMSsent === 'true') {
          const companyId = booking.company;
          if (counts[companyId]) {
            counts[companyId] += 1;
          } else {
            counts[companyId] = 1;
          }
        }
      });
      
      setSMSCounts(counts);
    } catch (error) {
      console.error('Error fetching SMS data:', error);
    }
  };
  
  useEffect(() => {
    fetchEventCounts();
    fetchBookingCounts();
    fetchDeviceCounts();
    fetchSMSCounts();
  }, []);
  
  const customStyles = {
    rows: {         
      style: {
        minHeight: '62px',
      }
    },

    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        paddingLeft: '4px',
        paddingRight: '8px',
      },
    },

    cells: {
      style: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },
  };

  const columns = [
    {
      name: '#ID',
      selector: row => row.company_ID,
      sortable: true,
      omit: window.innerWidth < 768
    },
    {
      name: 'Company',
      selector: row => row.company + '\n' + row.website,
      width: '200px',
      cell: row => (
          <div className='flex flex-row gap-3 name items-center pr-12 lg:pr-0'>
            <img 
              src={row.AddCompanyLogo || avator2}
              className='w-11 h-11' 
              alt={`Logo of ${row.company}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avator2; 
              }} 
            />
            <div className='flex flex-col'>
              <span className='text-xl font-bold' style={{color:"#101828"}}>{row.company}
              </span>
              <a href={ensureProtocol(row.Website)} target="_blank" rel="noopener noreferrer">
                {row.Website}
              </a>
            </div>
          </div>
      ),
      sortable: true,
    },
    {
      name: 'Contact',
      selector: row => `${row.ContactEmail} / ${row.ContactNumber}`,
      sortable: true,
      width: '170px',
      wrap: true,
      cell: row => (
        <div className="overflow-hidden text-ellipsis whitespace-normal" style={{ minWidth: '150px' }}>
          <div className="font-semibold text-gray-700">
            {row.ContactNumber}
          </div>
          <div className="text-sm">
            <a href={`mailto:${row.ContactEmail}`} className="">
              {row.ContactEmail}
            </a>
          </div>
        </div>
      ),
    },
    {
      name: 'Reg. Number',
      selector: row => row.RegistrationNumber,
      sortable: true,
    },
    {
      name: 'SMS Sent',
      selector: row => smsCounts[row.company] || 0,
      sortable: true,
      cell: row => (
        <div 
          style={{ cursor: 'pointer', color: "#349999"}}
          onClick={() => history.push(`/booking?company=${encodeURIComponent(row.company)}`)}
        >
          {smsCounts[row.company] || 0}
        </div>
      )
    },    
    {
      name: 'Devices',
      selector: row => deviceCounts[row.company] || 0,
      cell: row => {
        const deviceCount = deviceCounts[row.company] || 0;
        return (
          <button 
            style={{ cursor: 'pointer', color: "#349999"}}
            onClick={() => handleDeviceClick(row.company)}
          >
            {deviceCount}
          </button>
        );
      },
      sortable: true,
    },
    {
      name: 'Active bookings',
      selector: row => activeBookings[row.company] || 0,
      width: '120px',
      wrap: true,
      cell: row => {
        const activeBookingCount = activeBookings[row.company] || 0;
        return (
          <button 
            style={{ cursor: 'pointer', color: "#349999"}}
            onClick={() => handleActiveBookingClick(row.company)}
          >
            {activeBookingCount}
          </button>
        );
      },
      sortable: true,
    },
    {
      name: 'Events',
      selector: row => eventCounts[normalizeCompanyName(row.company)] || 0,
      cell: row => {
        const eventCount = eventCounts[normalizeCompanyName(row.company)] || 0;
        return (
          <button 
            style={{ cursor: 'pointer', color: "#349999"}}
            onClick={() => handleEventClick(row.company)}
          >
            {eventCount}
          </button>
        );
      },
      sortable: true,
    },            
    {
      name: '',
      button: true,
      cell: (row) => (
        <div className="flex flex-row gap-1 text-gray-600 cursor-pointer">
          <img onClick={() => onSettings(row)} src={edit} alt="Edit" className="w-5 h-5" />
          <button onClick={() => onSettings(row)} className="cursor-pointer">
            Settings
          </button>
        </div>
       
      )
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        <div className="flex flex-row gap-1 text-gray-600 cursor-pointer">
          <img onClick={() => handleDelete(row)} src={trash} alt="Delete" className="w-5 h-5" />
          <button onClick={() => handleDelete(row)} className="text-red-600">
            Delete
          </button>
        </div>
      )
    },  
    {
      name: '',
      button: true,
      cell: (row) => (
        <div className="flex flex-row gap-1 text-gray-600 cursor-pointer">
          <img onClick={() => onEditCompany(row)} src={edit} alt="Edit" className="w-5 h-5" />
          <button onClick={() => onEditCompany(row)} className="hover:underline text-gray-600">
            Edit
          </button>
        </div>
      )
    }
  ];

  function ensureProtocol(url) {
    if (!url) return url;
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={rows}
        pagination
        highlightOnHover
        responsive
        defaultSortAsc={true}
        sortIcon={customSortIcon}
        customStyles={customStyles}
        dense={false}
      />

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-gray-900' >Delete blog company</h2>
          <img
              onClick={closeDeleteModal}
              src={close}
              alt="User"
              className="cursor-pointer"
           />
        </div>

        <div>
          <p className='text-gray-500'>Are you sure you want to delete this company?</p>
        </div>

        <div className="mt-8 flex justify-spacebetween gap-4">
          <button
            style={{backgroundColor:'#FFFFF'}} onClick={closeDeleteModal}
            className="px-12 md:px-20 py-2 text-gray-600 border rounded"
          >
            Cancel
          </button>
          <button
            style={{backgroundColor:'#F05D38'}} onClick={confirmDelete}
            className="px-12 md:px-20 py-2 text-white bg-red-500 rounded"
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TableCompany;
