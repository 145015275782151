import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './pending.css';

const TableEdit = ({ headings, rows, handleDeleteCode }) => {
  const selectRow = (e) => {
    const parent = e.target.parentElement;
    if (parent !== null) {
      parent.classList.add('is-selected');
      const actions = parent.querySelector('#actions');
      actions?.classList.remove('is-hidden');
    }
  };

  const unselectRow = (e) => {
    const parent = e.target.parentElement;
    if (parent !== null) {
      parent.classList.remove('is-selected');
      const actions = parent.querySelector('#actions');
      actions?.classList.add('is-hidden');
    }
  };

  return (
    <div className="table-container overflow-x-auto">
      <table className="custom-table is-bordered is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th colSpan="3" style={{ border: 'none', backgroundColor: '' }}></th>
            <th colSpan="2" style={{ textAlign: 'center' }}>
              Valid From
            </th>
            <th colSpan="2" style={{ textAlign: 'center' }}>
              Valid To
            </th>
          </tr>
          <tr>
            {headings.map((heading, index) => (
              <th key={index} className="px-4 py-2 sm:px-6 sm:py-3">
                {heading}
              </th>
            ))}
            <th className="px-4 py-2 sm:px-6 sm:py-3">Actions</th>
          </tr>
        </thead>

        <tbody>
          {rows.map((row, index) => (
            <tr key={index} onMouseOver={selectRow} onMouseLeave={unselectRow}>
              {Object.values(row).map((value, subIndex) => (
                <td key={subIndex} className="px-4 py-2 sm:px-6 sm:py-3">
                  {value}
                </td>
              ))}
              <td id="actions" className="is-hidden px-4 py-2 sm:px-6 sm:py-3">
                <FontAwesomeIcon
                  icon={faTrash}
                  className="has-text-danger ml-3 cursor-pointer"
                  onClick={() => handleDeleteCode(row.code)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableEdit;
