import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getPendingUsersApi, approveUserApi, assignAdminApi,assignAdminByEmailApi,getAssignedAdminsApi, removeAdminApi } from '../js/api';
import './admin.css'

const AdminDashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [approvalStatus, setApprovalStatus] = useState({});

  useEffect(() => {
    fetchPendingUsers();
    fetchAssignedAdmins();
  }, []);

  const fetchPendingUsers = async () => {
    try {
      const { isAdmin, data } = await getPendingUsersApi();
      setIsAdmin(isAdmin);
      setPendingUsers(data);
      const initialApprovalStatus = {};
      data.forEach((user) => {
        initialApprovalStatus[user.id] = false;
      });
      setApprovalStatus(initialApprovalStatus);
    } catch (error) {
      console.error('Error fetching pending users:', error.message);
    }
  };

  const handleApproveUser = async (userId) => {
    try {
      setApprovalStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: true,
      }));
      await approveUserApi(userId);
      fetchPendingUsers();
    } catch (error) {
      console.error('Error approving user:', error.message);
      setApprovalStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: false,
      }));
    }
  };

  const handleAssignAdmin = async (userId, index) => {
    try {
      await assignAdminApi(userId);
      setPendingUsers((prevUsers) =>
        prevUsers.map((user, i) => (i === index ? { ...user, assigned: true } : user))
      );
      fetchAssignedAdmins();
    } catch (error) {
      console.error('Error assigning admin:', error.message);
    }
  };

  const handleAssignAdminByEmail = async () => {
    try {
      await assignAdminByEmailApi(newAdminEmail);
      fetchAssignedAdmins();
    } catch (error) {
      console.error('Error assigning admin by email:', error.message);
    }
  };

  const fetchAssignedAdmins = async () => {
    try {
      const response = await getAssignedAdminsApi();
      setAssignedAdmins(response);
      fetchPendingUsers();
    } catch (error) {
      console.error('Error fetching assigned admins:', error.message);
    }
  };

  const handleRemoveAdmin = async (adminId) => {
    try {
      await removeAdminApi(adminId);
      setAssignedAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== adminId));
    } catch (error) {
      console.error('Error removing admin:', error.message);
    }
  };
  console.log('Assigned Admins:', assignedAdmins);

  return (
    <div className="admin-dashboard-container">
      <div className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <NavLink to="/system">
          <button className="dashboard-button">Go to MainPage</button>
        </NavLink>
      </div>
      <h1>Pending Users</h1>
      <table className="admin-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Action</th>
            <th>Assign Admin</th>
          </tr>
        </thead>
        <tbody>
          {pendingUsers.map((user, index) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
               <td>
                  {approvalStatus[user.id] ? (
                    'Approved'
                  ) : (
                    <button onClick={() => handleApproveUser(user.id)}>Approve</button>
                  )}
                </td>
              <td>
                <button
                  onClick={() => handleAssignAdmin(user.id, index)}
                  disabled={user.assigned}
                >
                  {user.assigned ? 'Assigned' : 'Assign Admin'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <>
        <h1 className='assigned-header'>Assigned Admins</h1>
        <div className="assign-admin-section">
          <input
            type="email"
            value={newAdminEmail}
            onChange={(e) => setNewAdminEmail(e.target.value)}
            className="email-input"
            placeholder="Enter user's email"
          />
          <button onClick={handleAssignAdminByEmail} className="assign-admin-button">
            Assign as Admin
          </button>
        </div>
        {assignedAdmins && assignedAdmins.length > 0 ? (
          <table className="admin-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {assignedAdmins.map((admin) => (
                <tr key={admin.id}>
                  <td>{admin.username}</td>
                  <td>{admin.email}</td>
                  <td>
                    <button onClick={() => handleRemoveAdmin(admin.id)}>Remove Admin</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No assigned admins</p>
        )}
      </>
    </div>
  );
};

export default AdminDashboard;
