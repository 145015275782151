import React, { useState, useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { MdArrowUpward } from 'react-icons/md';
import trash from '../Assets/trash.svg';
import edit from '../Assets/edit.svg';
import Modal from './Modal';
import close from '../Assets/closeP.svg';
import './styles.css';
import axios from 'axios';
const customSortIcon = <MdArrowUpward />;

const sortText = (rowA, rowB, selector, direction) => {
  const a = selector(rowA).toString().toLowerCase();
  const b = selector(rowB).toString().toLowerCase();

  if (a < b) {
    return direction === 'asc' ? -1 : 1;
  }
  if (a > b) {
    return direction === 'asc' ? 1 : -1;
  }
  return 0;
};

const TableDevice = ({ rows, headings, onDelete, onEditDevice, userData, onStatusCheck, onSettingsClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [currentErrorDescription, setCurrentErrorDescription] = useState('');
  const [codeError, setCodeCurrentError] = useState('');
  const [isModalOpenLog, setIsModalOpenLog] = useState('');
  const [logData, setLogData] = useState('');
  const storedRole = userData?.role;

  const displayNames = {
    unitId: "Device ID",
    macAddress: "Mac Address",
    hardwareDetails: "Hardware Details",
    category: "Category",
    description: "Description",
    venue: "Venue",
    version: "Version",
    entryway: "Entryway",
    company: "Company",
    heartbeat: "Status",
    actions: 'Actions',
    error_description: 'Error Log',
    debug_log: 'Debug Log'
  };

  const handleDelete = (row) => {
    setCurrentRow(row);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentRow) {
      onDelete(currentRow.unitId);
    }
    setIsModalOpen(false);
  };

  const formatMacAddress = (macAddress) => {
    return macAddress.replace(/:/g, '');
  };

  const handleOpenFile = async (macAddress, setLogData, setIsModalOpenLog) => {
    const formattedMacAddress = formatMacAddress(macAddress);
    const payload = {
      filename: `${formattedMacAddress}_debug.txt`
    };

    try {
      const response = await axios.post('https://mqttengine.onrender.com/open-file', payload);
      if (response.status === 200) {
        const content = response.data.content ? response.data.content : response.data.msg;
        console.log('File opened successfully', content);
        setLogData(content);
        setIsModalOpenLog(true);
      } else {
        console.error('Error opening file:', response.data);
        setLogData(response.data.msg);
        setIsModalOpenLog(true);
      }
    } catch (error) {
      console.error('Error opening file:', error);
      setLogData('Error opening file');
      setIsModalOpenLog(true);
    }
  };
  
  const columns = headings.map(heading => ({
    name: displayNames[heading],
    selector: row => row[heading],
    sortable: !['debug_log','actions', 'Status', 'Actions'].includes(heading),
    sortFunction: !['debug_log', 'actions', 'Status', 'Actions'].includes(heading) ? (rowA, rowB, sortDirection) => sortText(rowA, rowB, row => row[heading], sortDirection) : undefined,
    wrap: true,
    cell: (row) => {
      if (heading === 'actions') {
        return (
          <div className="flex flex-row items-center gap-2 text-gray-600">
            { (storedRole === "System Admin" || storedRole === "Company Admin") && (
              <>
                <img 
                  onClick={ () => handleDelete(row)} 
                  src={trash} alt="Delete" 
                  className="w-5 h-5" style={{ cursor: 'pointer'}} 
                />
                <button 
                  onClick={ () => handleDelete(row)} 
                  className="text-red-500"
                >
                  Delete
                </button>
              </>
            )}
          </div>
        );
      }

      if (heading === 'debug_log') {
        return (
          <button 
            className="flex flex-row items-center gap-2 px-2 py-2 bg-black text-white" 
            onClick={() => handleOpenFile(row.macAddress, setLogData, setIsModalOpenLog)}
          >
            Log File
          </button>
        );
      }

      else if (heading === 'heartbeat') {
        const color = row.heartbeat === 'Active' ? '#027A48' : '#B42318'; 
        const backgroundColor = row.category === 'Control' ? (row.heartbeat === 'Active' ? '#ECFDF3' : '#FEF3F2') : 'transparent';
        return (
          <button 
            className="flex flex-row items-center gap-2 px-2 py-1" 
            style={{ backgroundColor }}
          >
            <span style={{ color: color }}>{row.category === 'Control' ? row.heartbeat : ''}</span>
          </button>
        );
      }
      
      else if (heading === 'Status') {
        return (
          <div className="flex flex-row items-center gap-2 text-gray-600">
            {(storedRole === "System Admin" || storedRole === "Company Admin") && (
              <>
                <button 
                  className='py-2.5 px-1' 
                  onClick={() => onStatusCheck(row)} 
                  style={{ backgroundColor: '#010808', color: '#FFFFFF'}}>
                    Check Status
                </button>
              </>
            )}
          </div>
        );
      }
      
      else if (heading === 'version') {
        return (
          <div className="flex flex-row items-center gap-2 text-gray-600">
            {(storedRole === "System Admin" || storedRole === "Company Admin") && (
              <>
                <span className='flex items-center '>{row.version}</span>
              </>
            )}
          </div>
        );
      }

      else if (heading === 'error_description') {
        const handleErrorClick = () => {
          if (row.error_description.toLowerCase() !== 'no_error' 
            && (storedRole === "System Admin" || storedRole === "Company Admin")) 
          {
            setCurrentErrorDescription(row.error_description);
            setCodeCurrentError(row.errorcode);
            setIsErrorModalOpen(true);
          }
        };

        if (storedRole === "System Admin" || storedRole === "Company Admin") {
          return (
            <div 
              className="flex flex-row items-center gap-2 text-gray-600 cursor-pointer" style={{ 
              backgroundColor: row.error_description.toLowerCase() === 'no_error' ? '#027A48' : '#B42318', 
              color: '#FFFFFF', 
              borderRadius: '0px'
              
            }}
              onClick={handleErrorClick}
            >
              { (storedRole === "System Admin" || storedRole === "Company Admin") && (
                <button className='py-2 px-2'>
                  { row.error_description.toLowerCase() === 'no_error' ? 'No Error' : 'Error' }
                </button>
              )}
            </div>
          );
        } else {
          return (
            <div className="flex flex-row items-center gap-2 text-gray-600">
              { "" }
            </div>
          );
        }
      }
      
      if (heading === 'Actionss') {
        return (
          <div className="flex flex-row items-center gap-2 text-gray-600">
            {(storedRole === "System Admin" || storedRole === "Company Admin") && (
              <>
                <img 
                  onClick={() => onSettingsClick(row)} 
                  src={edit} alt="Edit" 
                  className="w-5 h-5" 
                  style={{ cursor: 'pointer' }} 
                />
                <button 
                  onClick={() => onSettingsClick(row)} 
                  className="hover:underline text-gray-600"
                >
                  Settings
                </button>
              </>
            )}
          </div>
        );
      }
      
      else if (heading === 'Actions') {
        return (
          <div className="flex flex-row items-center gap-2 text-gray-600">
            {(storedRole === "System Admin" || storedRole === "Company Admin") && (
              <>
                <img 
                  onClick={() => onEditDevice(row)} 
                  src={edit} alt="Edit" 
                  className="w-5 h-5" 
                  style={{ cursor: 'pointer'}} 
                />
                <button 
                  onClick={() => onEditDevice(row)} 
                  className="hover:underline text-gray-600"
                >
                  Edit
                </button>
              </>
            )}
          </div>
        );
      }
      return row[heading];
    }
  }));

  const customStyles = {
    rows: {
      style: {
        minHeight: '62px',
      }
    },

    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        paddingLeft: '10px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },
  };

  return (
    <div className='table-container'>
      <DataTable
        columns={columns}
        data={rows}
        responsive
        pagination
        persistTableHead
        highlightOnHover
        customStyles={customStyles}
        sortIcon={customSortIcon}
        noHeader
        className="data-table"
      />

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-gray-900'>Error Details</h2>
          <img onClick={() => setIsErrorModalOpen(false)}
            src={close}
            alt="Close"
            className="cursor-pointer" />
        </div>
        <div className="mt-4">
          <p className='text-gray-900'>Error Code: {codeError}</p>
          <p className='text-gray-900'>Error Description: {currentErrorDescription}</p>
        </div>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='flex flex-row justify-between items-center' >
          <h2 className='text-gray-900' >Delete blog device</h2>
          <img
              onClick={closeDeleteModal}
              src={close}
              alt="User"
              className="cursor-pointer"
           />
        </div>
        <div>
          <p className='text-gray-500'>Are you sure you want to delete this device? </p>
        </div>

        <div className="mt-8 flex justify-spacebetween gap-4">
          <button
            style={{backgroundColor:'#FFFFF'}} onClick={closeDeleteModal}
            className="px-12 md:px-20 py-2 text-gray-600 border rounded"
          >
            Cancel
          </button>
          <button
            style={{backgroundColor:'#F05D38'}} onClick={confirmDelete}
            className="px-12 md:px-20 py-2 text-white bg-red-500 rounded"
          >
            Delete
          </button>
        </div>
      </Modal>

      <Modal isOpen={isModalOpenLog} onClose={() => setIsModalOpenLog(false)}>
        <div className='flex flex-row justify-between items-start debug mb-4'>
          <h2 className='text-gray-900 font-bold' >Debug log File</h2>
          <img
              onClick={() => setIsModalOpenLog(false)}
              src={close}
              alt="User"
              className="cursor-pointer"
           />
        </div>
        <div className='modal-content overflow-auto mt-16'>
          <p className='text-black whitespace-pre-wrap'>{logData}</p>
        </div>
      </Modal>
    </div>
  );
};

export default TableDevice;