  export const checkPasswordCriteria = (password) => {
    return {
      minLength: password.length >= 8 && password.length <= 15,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      numeric: /\d/.test(password),
      specialChar: /[@#$!%^&*]/.test(password),
    };
  };
  
  export const checkPasswordStrength = (password) => {
    const criteria = checkPasswordCriteria(password);
    const count = Object.values(criteria).filter(Boolean).length;
    if (count < 3) return "Weak";
    if (count < 5) return "Medium";
    return "Strong";
  };
  