import React, { useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { setNewPasswordApi } from '../../js/api';
import iconL from '../../Assets/left.svg';

const SetNewPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetToken } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSetNewPassword = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        setLoading(false);
        return;
      }

      const response = await setNewPasswordApi(resetToken, password);

      if (response.success) {
        history.push('/reset-success');
        setSuccess(true);
        setMessage('Password reset successfully');
      } else {
        setSuccess(false);
        setMessage('Password reset failed: ' + response.message);
      }
    } catch (error) {
      setError('Password reset failed: ' + error.message);
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-sm p-4">
        <h1 className="text-3xl mb-4 text-black font-bold text-center">Set new password</h1>
        <p className="text-gray-700 mb-6 text-center items-center justify-center">
          your new password must be different to previously used passwords.
        </p>

        <form onSubmit={handleSetNewPassword}>
          <div className="mb-6">
            <label className="text-gray-700 mb-2">New Password</label>
            <div className="relative">
              <input
                placeholder="Enter your new password"
                className="w-full p-2 mt-2 border text-gray-500 focus:outline-none"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 top-4 cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              />
            </div>
          </div>

          <div className="mb-6">
            <label className="text-gray-700 mb-2">Confirm Password</label>
            <div className="relative">
              <input
                placeholder="Confirm your new password"
                className="w-full p-2 mt-2 border text-gray-500 focus:outline-none"
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 top-4 cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              />
            </div>
          </div>

          <div className="mb-6">
            <button
              className="w-full bg-black text-white disabled:opacity-50 py-2"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <FontAwesomeIcon icon={faSpinner} spin /> Setting New Password...
                </span>
              ) : (
                'Set New Password'
              )}
            </button>
          </div>

          <div className="flex items-center justify-center mt-8">
            <Link to="/login" className="flex items-center justify-center text-black hover:underline">
              <img src={iconL} alt="User" className="mr-2" /> Back to Log in
            </Link>
          </div>
        </form>

        {error && <div className="text-red-500 mb-4 flex items-center justify-center">{error}</div>}
        {message && (
          <div className={success ? 'text-green-500' : 'text-red-500 flex items-center justify-center'}>
            {message}
          </div>
        )}

        {success && (
          <p className="text-gray-800 pt-12">
            Password reset successfully. You can now proceed to{' '}
            <span
              className="text-blue-500 hover:underline cursor-pointer"
              onClick={() => history.push('/reset-success')}
            >
              Reset
            </span>.
          </p>
        )}      
      </div>
    </div>
  );
};

export default SetNewPassword;
